import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Wysiwyg from "../../modules/wysiwyg";
import Link2 from "../../modules/link2";

const FlexibleSlider = ({ background, slides }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className={`flexible-slider layout_module flexible-${background}`}>
      {slides && (
        <Slider {...settings}>
          {slides.map((slide, index) => {
            return (
              <div key={index}>
                <div className="flexible-slider-item">
                  <div className="flexible-slider-content">
                    {slide.title && (
                      <h3 className="flexible-slider-title">{slide.title}</h3>
                    )}
                    <Wysiwyg className="flexible-slider-texte">
                      {slide.text}
                    </Wysiwyg>
                    {slide.cta && (
                      <p
                        className={`flexible-slider-cta cta cta--${slide.cta.type} cta--${slide.cta.type}-${background}`}
                      >
                        <Link2
                          to={slide.cta.url}
                          target={slide.cta.target}
                          type={slide.cta.type}
                        >
                          {slide.cta.title}
                        </Link2>
                      </p>
                    )}
                  </div>
                  <div className="flexible-slider-media">
                    <img src={slide.image.url} alt={slide.image.alt} />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};
export default FlexibleSlider;
