import React from 'react';

const FormThanks = ({ titre, message }) => {
    return (
        <div className="form_thanks">
            <p className="form_thanks_titre" dangerouslySetInnerHTML={{ __html: titre }}></p>
            <p className="form_thanks_message" dangerouslySetInnerHTML={{ __html: message }}></p>
        </div>
    )
}

export default FormThanks