import React from "react";
import { Link } from "react-router-dom";

const Link2 = ({ to, target, children, className, type }) => {

    const isLocalLink = url => {
        let link = document.createElement("a");
        link.href = url;
        return (link.host === document.location.host && type != 'download');
    }

    return (
        <>
            {
                isLocalLink(to) ?
                    (
                        <Link className={className} to={to} target={target}>{children}</Link>
                    ) : (
                        <a className={className} href={to} target={target}>{children}</a>
                    )
            }
        </>
    );
};
export default Link2;
