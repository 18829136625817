import React, { useEffect, useState } from "react";

const BlocVideoFull = ({ mediaType, media, textes }) => {
  let [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout = setTimeout(cycle, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentIndex]);

  function cycle() {
    setCurrentIndex((currentIndex + 1 + textes.length) % textes.length);
  }

  return (
    <div className="bloc-video-full">
      {mediaType === "video" && media && (
        <video
          src={media.url}
          autoPlay
          muted
          playsInline
          preload="auto"
          loop
        ></video>
      )}
      {mediaType === "image" && media && (
        <img src={media.url} alt={media.alt} />
      )}
      <div className="bloc-video-full-overlay"></div>
      {textes.map((texte, index) => (
        <div
          key={index}
          className={`bloc-video-full-texte ${
            index === 0 ? "bloc-video-full-texte--first" : ""
          } ${index === currentIndex ? "bloc-video-full-texte--current" : ""} ${
            index === textes.length - 1 ? "bloc-video-full-texte--last" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: texte.content }}
        ></div>
      ))}
    </div>
  );
};

export default BlocVideoFull;
