import Device from "./device";
import Sprite3D from "./sprite3D";
import Tools from "./tools";

var SpriteAnimation = function () {
    var elem = this;

    elem.canvasBufferAlpha = null;
    elem.canvasBufferAlphaContext = null;
    elem.canvasBufferSprite = null;
    elem.canvasBufferSpriteContext = null;
    elem.canvasRender = null;
    elem.canvasRenderContext = null;

    elem.isMoving = false;
    elem.startX = 0;
    elem.startFrame = "start";

    elem.lastX = 0;

    elem.currentBijou = null;
    elem.nextFrame = 0;
    elem.nbFrame = 100;
    elem.currentPercent = 0;

    elem.rendering = false;

    elem.init = function (canvas, params) {
        elem.canvasBufferAlpha = elem.createCanvas("animationSprite__canvasBufferAlpha", 1920, 1080);
        elem.canvasBufferSprite = elem.createCanvas("animationSprite__canvasBufferSprite", 1920, 1080);
        elem.canvasRender = canvas;

        elem.loadParams(params);

        elem.zoneEffect = elem.params.zoneEffect;
        elem.zoneParent = elem.params.zoneParent;

        elem.canvasBufferAlphaContext = elem.canvasBufferAlpha.getContext("2d");
        elem.canvasBufferSpriteContext = elem.canvasBufferSprite.getContext("2d");
        elem.canvasRenderContext = elem.canvasRender.getContext("2d");

        elem.zoneEffect.addEventListener("mousedown", elem.Events.zoomMouseDown);
        elem.zoneEffect.addEventListener("mouseup", elem.Events.zoomMouseUp);
        elem.zoneEffect.addEventListener("mousemove", elem.Events.zoomMouseMove);

        elem.zoneEffect.addEventListener("touchstart", elem.Events.zoomMouseDown);
        elem.zoneEffect.addEventListener("touchend", elem.Events.zoomMouseUp);
        elem.zoneEffect.addEventListener("touchmove", elem.Events.zoomMouseMove);

        if (elem.params.zoneParent != elem.canvasRender) {
            window.addEventListener("mouseup", elem.Events.zoomMouseUp);
            window.addEventListener("mousemove", elem.Events.zoomMouseMove);

            window.addEventListener("touchend", elem.Events.zoomMouseUp);
            window.addEventListener("touchmove", elem.Events.zoomMouseMove);
        }
        else {
            elem.canvasRender.addEventListener("mouseup", elem.Events.zoomMouseUp);
            elem.canvasRender.addEventListener("mousemove", elem.Events.zoomMouseMove);

            elem.canvasRender.addEventListener("touchend", elem.Events.zoomMouseUp);
            elem.canvasRender.addEventListener("touchmove", elem.Events.zoomMouseMove);
        }

        if (elem.params.clickOnParent) {
            elem.zoneParent.addEventListener("click", elem.Events.clickOnParent);
        }

        window.addEventListener("resize", elem.resize);
        elem.resize();
    };

    elem.loadParams = function (params) {
        elem.params = {
            zoneEffect: elem.canvasRender,
            zoneParent: elem.canvasRender,
            startFrame: "start",
            clickOnParent: false,
            vitesseFactor: 1
        };

        if (params != undefined) {
            if (params.zoneEffect != undefined)
                elem.params.zoneEffect = params.zoneEffect;
            if (params.zoneParent != undefined)
                elem.params.zoneParent = params.zoneParent;
            if (params.startFrame != undefined)
                elem.params.startFrame = params.startFrame;
            if (params.clickOnParent != undefined)
                elem.params.clickOnParent = params.clickOnParent;
            if (params.vitesseFactor != undefined)
                elem.params.vitesseFactor = params.vitesseFactor;
        }
    }

    elem.createCanvas = function (className, width, height) {
        var canvas = document.createElement("canvas");
        canvas.className = className;
        canvas.width = width;
        canvas.height = height;
        return canvas;
    };

    elem.resize = function () {
        elem.canvasRender.width = window.innerWidth;
        elem.canvasRender.height = window.innerHeight;
    };

    elem.loadBijou = function (step) {
        elem.currentBijou = Sprite3D.data.steps[step];
        elem.nbFrame = Sprite3D.data.steps[step].nbFrames;

        if (elem.params.startFrame == "start") {
            elem.currentPercent = 0;
            elem.nextFrame = 0;
        }
        else if (elem.params.startFrame == "middle") {
            elem.currentPercent = 0.5;
            elem.nextFrame = Math.ceil(elem.nbFrame / 2);
        }
        else {
            elem.currentPercent = 1;
            elem.nextFrame = elem.nbFrame - 1;
        }

        if (!elem.rendering)
            elem.render();
    }

    elem.changeFrame = function (event) {
        var percent = (Tools.getPage(event, "X") - elem.startX) / (elem.zoneParent.clientWidth / elem.params.vitesseFactor);
        elem.nextFrame = elem.ajustFrame(elem.startFrame + Math.floor(percent * elem.nbFrame));
        elem.currentPercent = elem.nextFrame / (elem.nbFrame - 1);
    };

    elem.ajustFrame = function (frame) {
        if (frame < 0) {
            return 0;
        }
        else if (frame >= elem.nbFrame) {
            return elem.nbFrame - 1;
        }
        else {
            return frame;
        }
    };

    elem.render = function () {
        elem.rendering = true;

        if (elem.isForcing) {
            var diff = elem.forcedFrame - elem.nextFrame;
            if (diff != 0) {
                elem.nextFrame += diff / Math.abs(diff);
                elem.currentPercent = elem.nextFrame / elem.nbFrame;
            }
            elem.isForcing = (diff != 0);
        }

        return elem.renderImage(elem.currentBijou, elem.nextFrame);
    };

    elem.renderImage = function (bijou, frame, callback) {
        elem.currentBijou = bijou;
        elem.nbFrame = elem.currentBijou.nbFrames;
        elem.nextFrame = frame;
        elem.currentPercent = elem.nextFrame / elem.nbFrame;
        var infosSprite = {}

        if (typeof (bijou.final) != "undefined" && typeof (bijou.final[frame]) != "undefined") {

            var spriteWidth = bijou.width;
            var spriteHeight = bijou.height;

            elem.canvasBufferSprite.width += 0;
            
            if(bijou.final[frame].typeImage == "imagedata" ){
                elem.canvasBufferSpriteContext.putImageData(bijou.final[frame], 0, 0);
            }
            else{
                spriteWidth = bijou.width * 0.75;
                spriteHeight = bijou.height * 0.75;
                elem.canvasBufferSpriteContext.drawImage(bijou.final[frame], 0, 0);
            }
            //Render Final
            elem.canvasRender.width += 0;

            ////////////
            var canvasWidth = elem.canvasRender.width;
            var canvasHeight = elem.canvasRender.height;

            var finalWidth = canvasWidth;
            var finalHeight = canvasHeight;
            var finalMarginTop = 0;
            var finalMarginLeft = 0;
            elem.canvasRender.height = window.innerHeight;

            if (Device.isLandscape) {
                infosSprite.finalWidth = finalWidth = canvasWidth;
                infosSprite.finalHeight = finalHeight = finalWidth * spriteHeight / spriteWidth;
                finalMarginTop = (canvasHeight - finalHeight) * 0.5;
                finalMarginLeft = 0;

                infosSprite.finalMarginTop = 0;
            }
            else {
                infosSprite.finalWidth = finalWidth = canvasWidth * 2.4;
                infosSprite.finalHeight = finalHeight = finalWidth * spriteHeight / spriteWidth;
                finalMarginTop = -Math.pow((finalHeight * 0.1), 1.05);
                finalMarginLeft = (canvasWidth - finalWidth) * 0.5;
                elem.canvasRender.height = finalHeight;

                infosSprite.finalMarginTop = finalMarginTop;
            }
            
            elem.canvasRenderContext.drawImage(elem.canvasBufferSprite,
                0, 0, spriteWidth, spriteHeight,
                finalMarginLeft, finalMarginTop, finalWidth, finalHeight);

            ////////////

            //Action du callback
            if (callback) {
                callback();
            }
        }

        return infosSprite;
    }

    elem.Events = {
        zoomMouseDown: function (event) {
            elem.isMoving = true;
            elem.startX = Tools.getPage(event, "X");
            elem.startFrame = elem.nextFrame;
        },

        zoomMouseUp: function (event) {
            if (elem.isMoving) {
                elem.isMoving = false;
                elem.lastX = 0;
            }
        },

        zoomMouseMove: function (event) {
            event.preventDefault();
            if (elem.isMoving) {
                elem.changeFrame(event);
                elem.lastX = Tools.getPage(event, "X");
            }
        },

        clickOnParent: function (event) {
            if (event.target == elem.zoneEffect) {
                event.preventDefault();
            }
            if (event.target == elem.zoneParent) {
                elem.isForcing = true;
                elem.forcedFrame = Math.floor(elem.nbFrame * (Tools.getPage(event, "X") - elem.zoneParent.getBoundingClientRect().left) / elem.zoneParent.clientWidth);
            }
        }
    }
}

export default SpriteAnimation;