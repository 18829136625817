import React from "react";

const FlexibleChapeau = ({ text, background }) => {
  return (
    <div className={`flexible-chapeau layout_module flexible-${background}`}>
      <p>{text}</p>
    </div>
  );
};
export default FlexibleChapeau;
