import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { updateGlobalStoreLang } from "../../Store/action";

import HeaderNav from "./header-nav";
import HeaderBurger from "./header-burger";

const Header = () => {
  let dataGlobal = useSelector((state) => state.global).global;
  let lang = useSelector((state) => state.global).lang;
  const { language } = dataGlobal.header;

  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = (statut) => {
    document
      .querySelector("html")
      .classList[statut ? "add" : "remove"]("withMenu");
    setOpenMenu(statut);
  };

  // DESTRUCTURING
  const { logo, menu_items } = dataGlobal.header;

  let anchorsVisible = useSelector((state) => state.anchor).statut;

  return (
    <header className={`header`}>
      <div className="header_wrapper">
        <LanguageSwitch datas={language} />
        <Link
          to={`/${lang === "fr" ? "" : lang + "/"}`}
          className="header_logo"
        >
          {logo && logo.src_white && (
            <img src={logo.src_white.url} alt={logo.src_white.alt} />
          )}
        </Link>

        <MenuSwitch onClick={() => toggleMenu(!openMenu)} />
        <HeaderNav
          items={menu_items}
          visible={anchorsVisible === "visible"}
        ></HeaderNav>
        <HeaderBurger
          open={openMenu}
          items={menu_items}
          onClose={() => toggleMenu(false)}
        ></HeaderBurger>
      </div>
    </header>
  );
};
export default Header;

const MenuSwitch = ({ onClick }) => {
  return (
    <div className="container_header_buttonburger">
      <button className="header_buttonburger" onClick={() => onClick()}>
        Menu
      </button>
    </div>
  );
};

/**
 * @component Changement de langue
 */
const LanguageSwitch = ({ datas }) => {
  const langStore = useSelector((state) => state.global).lang;
  const dispatch = useDispatch();

  return (
    <div className="header_lang header-burger_lang">
      {datas.map((item, i) => (
        <div key={i} className="header_lang--wrapper">
          {i !== 0 && <span className="header_lang--sep"></span>}
          <button
            key={`lang-${i}`}
            data-lang={item.lang}
            data-lang-active={item.lang === langStore}
            className="header_lang--btn"
            type="button"
            onClick={() => {
              dispatch(updateGlobalStoreLang(item.lang));
            }}
          >
            {item.text}
          </button>
        </div>
      ))}
    </div>
  );
};
