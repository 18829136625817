import React from "react";

import { HashLink as Link } from "react-router-hash-link";
const BurgerListe = ({ item, onClose, isHovered, setIsHovered }) => {
  function createMarkup(markup) {
    return { __html: markup };
  }
  return (
    <ul
      className={
        isHovered
          ? `header-burger_childrenliste`
          : `header-burger_childrenliste-none`
      }
    >
      {item.children.map((child, indexofchild) => {
        return (
          <li key={indexofchild}>
            <Link
              to={child.url}
              onClick={() => {
                setIsHovered(-1);
                onClose();
              }}
              dangerouslySetInnerHTML={createMarkup(child.text)}
            ></Link>
          </li>
        );
      })}
    </ul>
  );
};
export default BurgerListe;
