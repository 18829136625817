import React from "react";
import Wysiwyg from "../../modules/wysiwyg";

import CarrieresJob from "./carrieres-job";

const Carrieres = ({ banner, title, jobs, dictionnary, text }) => {
  // J'utilise un tableau qui affichera mes jobs de sur la colonne de gauche et un autre pour la colonneDroite
  const colonneGauche = [];
  const colonneDroite = [];
  // Je push les pairs à gauche et impair à droite
  for (let i = 0; i < jobs.length; i++) {
    if (i % 2 === 0) {
      colonneGauche.push(jobs[i]);
    } else {
      colonneDroite.push(jobs[i]);
    }
  }
  return (
    <>
      <div className="flexible-banniere">
        <div className="flexible-banniere-media">
          <img src={banner} alt="" />
        </div>
        <div className="flexible-banniere-content">
          <h1>{title}</h1>
          {text && (
            <Wysiwyg className="flexible-texte layout_module bloc-carrieres-layoutIntroText">
              {text}
            </Wysiwyg>
          )}
        </div>
      </div>
      <div className="bloc-carrieres layout_carrieres">
        <div className="bloc-carrieres-left">
          {colonneGauche.map((job, index) => (
            <CarrieresJob {...dictionnary} job={job} key={index}></CarrieresJob>
          ))}
        </div>
        <div className="bloc-carrieres-right">
          {colonneDroite.map((job, index) => (
            <CarrieresJob {...dictionnary} job={job} key={index}></CarrieresJob>
          ))}
        </div>
      </div>
      {/* <div className="carrieres-loadmore">
        <p className="bloc-carrieres-cta bloc-carrieres-cta--border">
          <a href="https://google.fr">...</a>
        </p>
      </div> */}
    </>
  );
};

export default Carrieres;
