import React from "react";
import { Loader } from "../loaders/loader.jsx";

import FlexibleImage from "../flexible/flexible-image";
import FlexibleCitation from "../flexible/flexible-citation";
import FlexibleTexteMiseEnAvant from "../flexible/flexible-texte-miseenavant";
import FlexibleGalerie from "../flexible/flexible-galerie";
import FlexibleVideo from "../flexible/flexible-video";
import FlexibleTextePicto from "../flexible/flexible-texte-picto";
import FlexibleBanniere from "../flexible/flexible-banniere.jsx";
import FlexibleTexte from "../flexible/flexible-texte.jsx";
import FlexibleChapeau from "../flexible/flexible-chapeau.jsx";
import FlexibleTitre from "../flexible/flexible-titre.jsx";
import FlexibleTexte2cols from "../flexible/flexible-texte-2cols.jsx";
import FlexibleTexteImage from "../flexible/flexible-texte-image";
import FlexibleCta from "../flexible/flexible-cta";
import FlexibleMargin from "../flexible/flexible-margin";
import FlexibleChiffres from "../flexible/flexible-chiffres";
import FlexibleSlider from "../flexible/flexible-slider";
import FlexibleLine from "../flexible/flexible-line";
import FlexibleSprite3D from "../flexible/flexible-sprite3D";

/**
 * Déclaration de la corresspondance nom reçu / nom du composant
 *
 */
const DynamicComponents = {
  block__image: FlexibleImage,
  block__quote: FlexibleCitation,
  block__text_push: FlexibleTexteMiseEnAvant,
  block__gallery: FlexibleGalerie,
  block__video: FlexibleVideo,
  block__picto_text: FlexibleTextePicto,
  block__banner: FlexibleBanniere,
  block__text_simple: FlexibleTexte,
  block__chapo: FlexibleChapeau,
  block__section_title: FlexibleTitre,
  block__text_2_cols: FlexibleTexte2cols,
  block__text_image: FlexibleTexteImage,
  block__ctas: FlexibleCta,
  block__margin: FlexibleMargin,
  block__numbers: FlexibleChiffres,
  block__slider: FlexibleSlider,
  block__line: FlexibleLine,
  block__3d: FlexibleSprite3D
};

/**
 * ## Generation des composants dynamiquement suivant JSON
 * @param {object} block
 * cf : https://www.storyblok.com/tp/react-dynamic-component-from-json
 */
const ComponentByJSON = (block, mainTitle, mainAnchors) => {
  if (typeof DynamicComponents[block.component] !== "undefined") {
    let datas = {
      key: block._uid,
      ...block.datas,
    };

    if (block.component === "block__banner") {
      datas.mainTitre = mainTitle;
      datas.mainAnchors = mainAnchors;
    }

    return React.createElement(DynamicComponents[block.component], datas);
  }
  // component doesn't exist yet
  return React.createElement(() => <Loader />, { key: block._uid });
};
export default ComponentByJSON;
