import { IS_MOBILE, IS_TABLET, IS_TABLET_WIDE, IS_DESKTOP } from "../action";

/**
 * REDUCER DE LA CONFIGURATION DES BREAKPOINTS
 * @param {*} state 
 * @param {*} action 
 */

const initialState = {
    mobile: window.matchMedia('(max-width:767px)').matches ? true : false,
    tablet: window.matchMedia('(min-width:768px) and (max-width:1023px)').matches ? true : false,
    tabletwide: window.matchMedia('(min-width:1024px) and (max-width:1099px)').matches || window.matchMedia('(min-width:1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none)').matches ? true : false,
    desktop: window.matchMedia('(min-width: 1100px)').matches && !window.matchMedia('(min-width:1024px) and (max-width:1099px)').matches && !window.matchMedia('(min-width:1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none)').matches ? true : false
}

export function breakpointReducer(state = initialState, action) {
    switch (action.type) {
        case IS_MOBILE:
            return { ...state, mobile: action.value };
        case IS_TABLET:
            return { ...state, tablet: action.value };
        case IS_TABLET_WIDE:
            return { ...state, tabletwide: action.value };
        case IS_DESKTOP:
            return { ...state, desktop: action.value };
        default:
            return state;
    }
}