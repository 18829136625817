import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import Axios from 'axios';
import SelectBox from '../../modules/select-box';
import FormThanks from './form-thanks';
import { FriendlyCaptcha } from './friendly-captcha';

const ContactForm = ({ open, close }) => {

    let lang = useSelector(state => state.global).lang;

    let { email_form, form_messages } = useSelector(state => state.global).global;
    let { fields, optin_rgpd, submit_button, title, subjects, recaptcha_public_key } = email_form;
    let { legend, error } = form_messages;

    const [isValidate, setValidate] = useState(false);
    const [isErrors, setIsErrors] = useState([]);
    const [currentSuject, setCurrentSuject] = useState("");

    const [thanksTitle, setThanksTitle] = useState("");
    const [thanksMessage, setThanksMessage] = useState("");
    const [disabled, setDisabled] = useState(true)
    const [solution, setSolution] = useState(null)

    const { register, handleSubmit, trigger, errors } = useForm();

    const form = useRef(null);
    const resetBtn = useRef(null);

    const onSubmit = (datas) => {
        const formateDatas = new FormData();
        formateDatas.append('type', 'email');
        for (const [key, value] of Object.entries(datas)) {
            formateDatas.append(key, value);
        }

        formateDatas.append('captcha-response', solution);
        
        const dataFetch = Axios.post(`${process.env.REACT_APP_APP}/wp-json/df-contact/v1/save/?lang=${lang}`, formateDatas);
        dataFetch.then(dataFetch => {
            if(dataFetch.data.data.status === 200) {
                setThanksTitle(dataFetch.data.data.message.title);
                setThanksMessage(dataFetch.data.data.message.text);
                resetBtn && resetBtn.current.click();
                setValidate(true);
            } else {
                let listErrors = [];
                Object.entries(dataFetch.data.data.errors).map(([key, value]) => listErrors.push(value));
                setIsErrors(listErrors);
            }
        })
    }

    const renderMarkup = (markup) => {
        return { __html: markup };
    };

    const replaceBr = (markup) => {
        let regex = new RegExp("<br ?/?>", "g")
        return markup.replace(regex, "\n");
    }

    const handleClose = () => {
        close();
        resetBtn && resetBtn.current.click();
        setValidate(false);
    }

    const closePanel = e => {
        if (e.target.classList.contains("form")) {
            close();
            resetBtn && resetBtn.current.click();
            setValidate(false);
        }
    }

    function renderComponent() {

        return (
            <form className="form form_email" ref={form} onSubmit={handleSubmit(onSubmit)} data-open={open} onClick={e => closePanel(e)}>
                <div className="form_box">
                    <button className="form_close icon-close" type="button" onClick={handleClose}></button>

                    {isValidate ? (
                        <FormThanks titre={thanksTitle} message={thanksMessage}></FormThanks>
                        ) : (
                            <>
                                <h3 className="form_title" dangerouslySetInnerHTML={renderMarkup(title)}></h3>
                                <p className="form_error form_error-main">
                                    {Object.entries(errors).length > 0 && error.required_fields}&nbsp;
                                    {isErrors.length > 0 && isErrors.map((value, index) => <span className="item" key={`error_${index}`}>{value}</span>)}
                                </p>

                                <div className="form_field">
                                    <input ref={register} name="company" type="text" className="form_input form_input--society" placeholder={fields.company} />
                                </div>

                                <div className="form_field" data-error={errors.lastname ? true : false}>
                                    <input ref={register({ required: true })} name="lastname" type="text" className="form_input form_input--lastname" placeholder={fields.lastname} />
                                    <p className="form_error">{errors.lastname && error.required_field}&nbsp;</p>
                                </div>

                                <div className="form_field" data-error={errors.firstname ? true : false}>
                                    <input ref={register({ required: true })} name="firstname" type="text" className="form_input form_input--firstname" placeholder={fields.firstname} />
                                    <p className="form_error">{errors.firstname && error.required_field}&nbsp;</p>
                                </div>

                                <div className="form_field" data-error={errors.email ? true : false}>
                                    <input ref={register({required: 'Required',pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,message: "invalid email address"}})} name="email" type="text" className="form_input form_input--email" placeholder={fields.email} />
                                    <p className="form_error">{errors.email && error.required_field}&nbsp;</p>
                                </div>

                                <div className="form_field" data-error={errors.subject ? true : false}>
                                    <input ref={register({ required: true })} type="hidden" name="subject" value={currentSuject}></input>
                                    <SelectBox onChange={(key, value) => { setCurrentSuject(key); setTimeout(trigger, 100) }} register={register} nameInput="subject" options={Object.entries(subjects)} defaultLabel={fields.subject} classNameButton={"form_item"}></SelectBox>
                                    <p className="form_error">{errors.subject && error.required_field}&nbsp;</p>
                                </div>

                                <div className="form_field" data-error={errors.message ? true : false}>
                                    <textarea ref={register({ required: true })} name="message" className="form_input form_input--message" placeholder={replaceBr(fields.message)} />
                                    <p className="form_error">{errors.message && error.required_field}&nbsp;</p>
                                </div>

                                <div className="form_legend">{legend.required_fields}</div>
                                {recaptcha_public_key && (
                                <div className="form_input form_input--rgpd">
                                    <FriendlyCaptcha 
                                        setDisabled={setDisabled}
                                        setSolution={setSolution} 
                                        siteKey={recaptcha_public_key}
                                    />
                                </div>
                                )}

                                <div className="form_field" data-error={errors.optinrgpd ? true : false}>
                                    <input ref={register({ required: true })} name="optin_rgpd" id="contact_optin_rgpd" type="checkbox" className="form_input form_input--optinrgpd" value="1" />
                                    <label htmlFor="contact_optin_rgpd">{optin_rgpd}</label>
                                    <p className="form_error">{errors.message && error.optinrgpd}&nbsp;</p>
                                </div>

                                <div className="form_submit">
                                    <button  disabled={disabled} type="submit" className="form_submit--btn anim2">{submit_button}</button>
                                    <input className="form_reset" type="reset" ref={resetBtn} />
                                </div>

                            </>
                        )
                    }
                </div>
            </form >
        )
    }
    return email_form && renderComponent();
}
export default ContactForm;