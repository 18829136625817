import React from "react";
import Link2 from "../../modules/link2";

const FlexibleCTA = ({ background, ctas }) => {
  return (
    <div className={`flexible-cta layout_module flexible-${background}`}>
      {ctas.length > 0 && (
        <ul className="flexible-cta_liste">
          {ctas.map((cta, index) => {
            return (
              <li key={index} className="flexible-cta_item">
                <p
                  className={`flexible-text-image-cta cta cta--${cta.type} cta--${cta.type}-${background}`}
                >
                  <Link2 to={cta.url} target={cta.target} type={cta.type}>
                    {cta.title}
                  </Link2>
                </p>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default FlexibleCTA;
