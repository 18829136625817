import React from "react";

const EnTeteArticle = ({ title, children }) => {
  const renderMarkup = (markup) => {
    return { __html: markup };
  };
  return (
    <div className="entete-article">
      <h1
        className="entete-article-titre"
        dangerouslySetInnerHTML={renderMarkup(title)}
      ></h1>
      <div className="entete-article-box">{children}</div>
    </div>
  );
};

export default EnTeteArticle;
