import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import StickyBar from "../contact/sticky-bar";
import { useInView } from "react-intersection-observer";

const Footer = ({ isHome }) => {
  // let dataGlobal = require('../../api/global.json');
  let dataGlobal = useSelector((state) => state.global).global;
  let { desktop } = useSelector((state) => state.breakpoint);
  const { logo } = dataGlobal.header; // links

  const [progress, setProgress] = useState(0);
  const [ref, inView, entry] = useInView({
    threshold: [0.1, 0.2, 0.3, 0.4, 0.5],
    infinite: true,
  });
  useEffect(() => {
    if (entry) {
      if (inView && desktop) {
        setProgress(entry.intersectionRatio);
      } else {
        setProgress(0);
      }
    }
  }, [inView, entry, desktop]);

  const { links, copyright } = dataGlobal.footer;
  const { twitter, linkedin } = dataGlobal.socials;

  return (
    <>
      <footer ref={ref} className="footer">
        <div className="layout">
          <div className="footer_inner">
            <div className="footer_links">
              <ul className="footer_links--list">
                {links.map(({ text, url }, i) => (
                  <li className="footer_links--item" key={`footer-${i}`}>
                    {url.indexOf("#") === 0 ? (
                      <a href={url} className="footer_links--link">
                        {text}
                      </a>
                    ) : (
                      <Link to={url} className="footer_links--link">
                        {text}
                      </Link>
                    )}
                    {i + 1 !== links.length && (
                      <span className="footer_links--sep">-</span>
                    )}
                  </li>
                ))}
                <li className="footer_links--copyrights">
                  <span className="footer_links--sep">-</span>
                  {copyright}
                </li>
              </ul>
            </div>

            {logo && logo.src_color && (
              <div className="footer_bottom">
                {dataGlobal.socials && (
                  <div className="footer_bottom_icon">
                    {linkedin && (
                      <a href={linkedin} target="_blank">
                        <div className="footer_bottom_icon-linkedin"></div>
                      </a>
                    )}
                    {twitter && (
                      <a href={twitter} target="_blank">
                        <div className="footer_bottom_icon-twitter"></div>
                      </a>
                    )}
                  </div>
                )}
                <div className="footer_bottom_logo">
                  <img src={logo.src_color.url} alt={logo.src_color.alt} />
                </div>
              </div>
            )}
          </div>
        </div>
      </footer>

      <StickyBar isHome={isHome} progress={progress} />
    </>
  );
};
export default Footer;
