// GLOBAL STATE
export function updateGlobalStoreGlobal(datas){
	return {type: GLOBAL_UPDATE_GLOBAL, value: datas}
}

export function updateGlobalStoreRoutes(datas){
	return {type: GLOBAL_UPDATE_ROUTES, value: datas}
}

export function updateGlobalStorePage(datas){
	return {type: GLOBAL_UPDATE_PAGE, value: datas}
}

export function updateGlobalStoreLang(datas){
	return {type: GLOBAL_UPDATE_LANG, value: datas}
}

export function updateGlobalisLoaded(bool){
	return {type: GLOBAL_IS_LOADED, value: bool}
}

// ANCHORS
export function updateGlobalStoreAnchors(datas){
	return {type: GLOBAL_UPDATE_ANCHORS, value: datas}
}
export function updateGlobalStoreAnchorsVisible(statut){
	return {type: GLOBAL_UPDATE_ANCHORS_VISIBLE, value: statut}
}

// SCROLL STATE
export function updateGlobalScrollLock(bool){
	return {type: GLOBAL_IS_SCROLLLOCKED, value: bool}
}

// Breakpoints
export function updateIsMobile(bool){
	return {type: IS_MOBILE, value: bool}
}
export function updateIsTablet(bool){
	return {type: IS_TABLET, value: bool}
}
export function updateIsTabletWide(bool){
	return {type: IS_TABLET_WIDE, value: bool}
}
export function updateIsDesktop(bool){
	return {type: IS_DESKTOP, value: bool}
}

// LOADER
export function updateLoaderStoreIsReady(bool){
	return {type: LOADER_IS_READY, value: bool}
}
export function updateLoaderStoreStatus(percent){
	return {type: LOADER_UPDATE_STATUS, value: percent}
}
export function updateLoaderStoreImages(bool){
	return {type: LOADER_UPDATE_IMAGES, value: bool}
}


export const GLOBAL_UPDATE_GLOBAL = 'UPDATE_GLOBAL';
export const GLOBAL_UPDATE_ROUTES = 'UPDATE_ROUTES';
export const GLOBAL_UPDATE_PAGE = 'UPDATE_PAGE';
export const GLOBAL_UPDATE_LANG = 'UPDATE_LANG';
export const GLOBAL_UPDATE_ANCHORS = 'UPDATE_ANCHORS';
export const GLOBAL_UPDATE_ANCHORS_VISIBLE = 'UPDATE_ANCHORS_VISIBLE';
export const GLOBAL_IS_LOADED = 'IS_LOADED';
export const GLOBAL_IS_SCROLLLOCKED = 'IS_SCROLLLOCKED';
export const IS_MOBILE = 'IS_MOBILE';
export const IS_TABLET = 'IS_TABLET';
export const IS_TABLET_WIDE = 'IS_TABLET_WIDE';
export const IS_DESKTOP = 'IS_DESKTOP';
export const LOADER_IS_READY = 'IS_READY';
export const LOADER_UPDATE_STATUS = 'UPDATE_STATUS';
export const LOADER_UPDATE_IMAGES = 'UPDATE_IMAGES';