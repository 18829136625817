import React from "react";
import Wysiwyg from "../../modules/wysiwyg";

const NewsOnceComponent = ({ article }) => {
  return (
    <>
      <div className="bloc-news-component">
        <img src={article.image.url} alt={article.image.alt} />

        <div className="bloc-news-component-upper">
          <p className="bloc-news-component-dotted">{article.date}</p>
          <div>
            <h2>{article.title}</h2>
            <Wysiwyg>{article.content}</Wysiwyg>
          </div>
        </div>
        <div className="bloc-news-component-lower">
          {article.cta && (
            <a
              href={article.cta.url}
              target={article.cta.target}
              className="bloc-news-component-lower-arrow"
            >
              <span className="arrow-lign arrow"></span>
              <p>{article.cta.title}</p>
            </a>
          )}
          {article.document && (
            <div className="bloc-news-component-lower-download">
              <span className="icon-download"></span>
              <a
                className="infosReg-information-bloc-contenu-blocdownload__text"
                href={article.document ? article.document : undefined}
                download
                target="_blank"
              >
                {article.document_cta}
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewsOnceComponent;
