import React from "react";
import BackgroundPoints from '../../modules/background-points';
import { useSelector } from 'react-redux';

const LoaderHome = () => {
    let step = useSelector(state => state.loader).status;
    let { loader } = useSelector(state => state.global).global;
    let lang = useSelector(state => state.global).lang;

    let dataGlobal = useSelector(state => state.global).global;
    const { logo } = dataGlobal.header;

    function getKilometers(step) {
        let max = 500000000
        if (loader) {
            let maxInt = parseInt(loader.count_max);
            if (isNaN(maxInt)) {
                maxInt = 500000000;
            }
        }

        let size = String(max).length;
        let value = Math.round(step * max);
        let number = String(value).padStart(size, '0')

        let final = "";

        for (let i = number.length; i >= 0; i = i - 3) {
            final = "<span>" + number.substring(i - 3, i) + "</span>" + final;
        }

        return final;
    }

    return (
        <>
            <header className={`header header--loader`}>
                <div className="header_wrapper">
                    {logo && logo.src_white && (
                        <span className="header_logo"><img src={logo.src_white.url} alt={logo.src_white.alt} /></span>
                    )}
                </div>
            </header>
            <div className="loader-home">
                <BackgroundPoints></BackgroundPoints>
                <div className="loader-home-box">
                    <p className="loader-home-number" dangerouslySetInnerHTML={{ __html: getKilometers(step) }}></p>
                    <p className="loader-home-unit">kms {lang === "fr" ? "parcourus" : "traveled"}</p>
                    <div className="loader-home-barre">
                        <div className="loader-home-curseur" style={{ width: (step * 100) + "%" }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoaderHome;