import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { updateGlobalStorePage } from "../Store/action";
import useApi from "../Hooks/useApi";
import { LoaderPage } from "../Components/loaders/loader";

// Components
import ComponentByJSON from "../Components/routes/component-from-json";
import Metatags from "../Components/metas-tags/meta-tags";
import Footer from "../Components/footer/footer";
import BackgroundPoints from "../modules/background-points";
import EnTeteArticleFilter from "../modules/entete-articleFilter";
import Presse from "../Components/presse/presse";

const TemplatePress = ({ _uid }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [year, setYear] = useState("");
  const [category, setCategory] = useState("");
  const [dataFetch, isLoaded] = useApi({ name: "pages", _uid, year, category });

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("content-page"));
  }, [dispatch, isLoaded]);

  useEffect(() => {
    if (isLoaded && firstLoad) {
      setFirstLoad(false);
      dataFetch.content.default_year && setYear(dataFetch.content.default_year);
    }
  }, [isLoaded]);

  function renderPage() {
    const { metas, flexibles_content, content } = dataFetch;

    return (
      <>
        <BackgroundPoints></BackgroundPoints>
        <Metatags {...metas} />
        <div className="flexible-banniere">
          <div className="flexible-banniere-media">
            <img src={content.banner} alt="" />
          </div>
          <div className="flexible-banniere-content">
            <h1>{content.title}</h1>
          </div>
        </div>
        <div className="layoutPresse">
          <EnTeteArticleFilter
            setYear={setYear}
            setCategory={setCategory}
            year={year}
            years={content && content.years}
            category={category}
            categories={content && content.categories}
            bgColor={"white"}
            bigPadding={"bloc-filter-big-padding"}
            {...content}
          >
            <Presse {...content}></Presse>
          </EnTeteArticleFilter>
        </div>

        {flexibles_content &&
          flexibles_content.map((block) => ComponentByJSON(block))}

        <Footer />
      </>
    );
  }

  return isLoaded ? renderPage() : <LoaderPage />;
};

export default TemplatePress;
