import React from "react";
// import { GetTranslate } from "../utils/getTranslate";

export const Loader = () => {
	return(
		<div className="loader" data-skeleton="true">
			{/* <GetTranslate keyword="loading" /> */}
		</div>
	)
}
 
export const LoaderPage = ({load}) =>{
	return <div className="loader-page" data-load={load}>
		<div className="loader-page_center">
			<div className="loader-page_logo"></div>
			<div className="loader-page_spinner">
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	</div>
}