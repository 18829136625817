import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { updateGlobalStorePage } from "../Store/action";
import useApi from "../Hooks/useApi";
import { LoaderPage } from "../Components/loaders/loader";

// Components
import ComponentByJSON from "../Components/routes/component-from-json";
import Metatags from "../Components/metas-tags/meta-tags";
import EnTeteArticle from "../modules/entete-article";
import InfosReglementees from "../Components/infos-reglementees/infos-reg";
import Footer from "../Components/footer/footer";
import BackgroundPoints from "../modules/background-points";

const TemplateInfosReglementees = ({ _uid }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [year, setYear] = useState("2020");
  const [dataFetch, isLoaded] = useApi({ name: "pages", _uid, year });

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("content-page"));
  }, [dispatch, isLoaded]);

  useEffect(() => {
    if (isLoaded && firstLoad) {
      setFirstLoad(false);
      dataFetch.content.default_year && setYear(dataFetch.content.default_year);
    }
  }, [isLoaded]);

  function renderPage() {
    const { metas, flexibles_content, content } = dataFetch;

    return (
      <>
        <BackgroundPoints></BackgroundPoints>
        <Metatags {...metas} />
        <div className="layoutInfoReg">
          <EnTeteArticle title={content && content.title}>
            <InfosReglementees
              {...content}
              setYear={setYear}
            ></InfosReglementees>
          </EnTeteArticle>
        </div>
        {flexibles_content &&
          flexibles_content.map((block) => ComponentByJSON(block))}

        <Footer />
      </>
    );
  }

  return isLoaded ? renderPage() : <LoaderPage />;
};

export default TemplateInfosReglementees;
