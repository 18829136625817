import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updateGlobalStoreAnchors,
  updateGlobalStoreAnchorsVisible,
} from "../../Store/action";
import { useInView } from "react-intersection-observer";

const FlexibleBanniere = ({
  media_type,
  video,
  image,
  mainTitre,
  mainAnchors,
}) => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStoreAnchors(mainAnchors));
  }, [dispatch, mainAnchors]);

  const [ref, inView] = useInView({ threshold: 0.5, infinite: true });
  useEffect(() => {
    if (inView) {
      dispatch(updateGlobalStoreAnchorsVisible("hidden"));
    } else {
      dispatch(updateGlobalStoreAnchorsVisible("visible"));
    }

    return () => {
      dispatch(updateGlobalStoreAnchorsVisible("none"));
    };
  }, [inView]);

  const goTo = (id) => {
    var el = document.getElementById(id);
    window.scrollTo(0, el.offsetTop - 15);

    return false;
  };

  const renderMarkup = (markup) => {
    return { __html: markup };
  };
  return (
    <div className="flexible-banniere">
      <div className="flexible-banniere-media">
        {media_type === "video" ? (
          <video loop autoPlay muted playsInline>
            <source src={video} type="video/mp4" />
          </video>
        ) : (
          <img src={image.url} alt={image.alt} />
        )}
      </div>
      {/* VOIR COMMENT FAIRE POUR LE FAIRE DYNAMIQUEMENT VU QUE CA L'API SORT LE TITRE DANS CONTENT et non pas dans flexibles */}
      <div ref={ref} className="flexible-banniere-content">
        <h1 dangerouslySetInnerHTML={renderMarkup(mainTitre)}></h1>
        <div className="flexible-banniere-content__anchors">
          {mainAnchors &&
            Object.entries(mainAnchors).map((anc, index) => (
              <a key={index} onClick={() => goTo(`${anc[0]}`)}>
                {anc[1]}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};
export default FlexibleBanniere;
