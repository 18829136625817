var Tools = {
    getPage: function (event, type) {
        if ("ontouchstart" in window && event.type == "touchend")
            return event.originalEvent.changedTouches[0]["client" + type];
        else if ("ontouchstart" in window) {
            if (event.touches == undefined)
                return event["client" + type];
            else
                return event.touches[0]["client" + type];
        }
        else
            return event["client" + type];
    },

    ajustIndex: function (index, max) {
        var currentIndex = index.toString();
        var size = max.toString().length;
        while (currentIndex.length < size)
            currentIndex = "0" + currentIndex;
        return currentIndex;
    }
}

export default Tools;