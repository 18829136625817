import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateGlobalStorePage } from "../Store/action";
import { Link } from "react-router-dom";

import BackgroundPoints from "../modules/background-points";
import Footer from "../Components/footer/footer";

const Template404 = () => {
  let dataGlobal = useSelector((state) => state.global).global;

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("page-404"));
  }, [dispatch]);

  return (
    <>
      <BackgroundPoints></BackgroundPoints>
      <div className="headerPage">
        <div className="headerPage_layout">
          <div className="headerPage_inner">
            <h1 className="headerPage_title">
              4<span>0</span>4
            </h1>
            <p className="headerPage_description">
              {dataGlobal.not_found.text}
            </p>
          </div>
        </div>

        <p className="headerPage_exit">
          <span className="arrow-lign arrow"></span>
          <a href={dataGlobal.not_found.cta.url}>
            {dataGlobal.not_found.cta.title}
          </a>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Template404;
