import React from "react";
import Link2 from "../../modules/link2";
import Wysiwyg from "../../modules/wysiwyg";

const FlexibleTexte2cols = ({ background, column_1, column_2 }) => {
  return (
    <div
      className={`flexible-texte-2cols layout_module flexible-${background}`}
    >
      <div className="flexible-texte-2cols__col">
        {column_1.title && (
          <h3 className="flexible-texte-2cols__title">{column_1.title}</h3>
        )}
        {column_1.text && (
          <Wysiwyg className="flexible-texte-2cols__text wysiwyg">
            {column_1.text}
          </Wysiwyg>
        )}
        {column_1.cta && (
          <p
            className={`flexible-texte-2cols-cta cta cta--${column_1.cta.type} cta--${column_1.cta.type}-${background}`}
          >
            <Link2
              to={column_1.cta.url}
              target={column_1.cta.target}
              type={column_1.cta.type}
            >
              {column_1.cta.title}
            </Link2>
          </p>
        )}
      </div>
      <div className="flexible-texte-2cols__col">
        {column_2.title && (
          <h3 className="flexible-texte-2cols__title">{column_2.title}</h3>
        )}
        {column_2.text && (
          <Wysiwyg className="flexible-texte-2cols__text">
            {column_2.text}
          </Wysiwyg>
        )}
        {column_2.cta && (
          <p
            className={`flexible-texte-2cols-cta cta cta--${column_2.cta.type} cta--${column_2.cta.type}-${background}`}
          >
            <Link2
              to={column_2.cta.url}
              target={column_2.cta.target}
              type={column_2.cta.type}
            >
              {column_2.cta.title}
            </Link2>
          </p>
        )}
      </div>
    </div>
  );
};
export default FlexibleTexte2cols;
