import React, { useState } from "react";
import Wysiwyg from "../../modules/wysiwyg";

const CarrieresJob = ({
  job,
  contract,
  location,
  published,
  apply,
  download,
}) => {
  const [isShow, setisShow] = useState(false);
  const renderMarkup = (markup) => {
    return { __html: markup };
  };

  return (
    <>
      {job ? (
        <div className="bloc-carrieres-job">
          <div className="bloc-carrieres-job-top">
            <div className="bloc-carrieres-job-top-part1">
              <h1>
                {published} : <span>{job.date}</span>
              </h1>
              <h1>
                {location} : <span>{job.location}</span>
              </h1>
            </div>
            <h1 className="bloc-carrieres-job-top-part2">
              {contract} : <span>{job.contract}</span>
            </h1>
          </div>
          <div className="bloc-carrieres-job-mid">
            <h1>{job.title}</h1>
            <h2 dangerouslySetInnerHTML={renderMarkup(job.intro)}></h2>
            <div
              className={
                isShow
                  ? `bloc-carrieres-job-mid-infos`
                  : `bloc-carrieres-job-mid-infos-none`
              }
            >
              <Wysiwyg className="bloc-carrieres-job-mid-infos-wysiwyg">
                {job.content}
              </Wysiwyg>
            </div>
          </div>
          <div className="bloc-carrieres-job-bottom">
            <button
              onClick={(e) => {
                console.log(isShow);
                e.preventDefault();
                setisShow(!isShow);
              }}
            >
              {isShow ? "-" : "+"}
            </button>
          </div>
          <div
            className={
              isShow
                ? `bloc-carrieres-job-bottom-infos`
                : `bloc-carrieres-job-bottom-infos-none`
            }
          >
            <p className="bloc-carrieres-cta bloc-carrieres-cta--border">
              <a href={`mailto:${job.email}`}>{apply}</a>
            </p>
            <p className="flexible-texte-cta cta bloc-carrieres-job-bottom-infos-download">
              <a href={job.file} download>
                {download}
              </a>
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CarrieresJob;
