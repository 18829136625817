var Device = {
    isTouch: false,
    isPaysage: false,
    isMobile: false,
    isBigMobile: false,
    isTablette: false,
    isDesktop: false,
    isSmallDesktop: false,
    isIOS: false,
    isChrome: false,
    isIE11: false,

    isLandscape : false,

    widthDesktop: 1366,
    widthTablet: 768,
    widthMobile: 320,

    init: function () {
        window.addEventListener("resize", Device.check);
        Device.check();
    },
    check: function () {
        Device.isTouch = ('ontouchstart' in window);
        Device.isPaysage = (window.innerWidth > window.innerHeight);

        Device.isMobile = (window.innerWidth < 768);
        Device.isBigMobile = Device.isMobile && (window.innerWidth >= 600);
        Device.isTablette = (window.innerWidth >= 768 && window.innerWidth <= 1024);
        Device.isDesktop = (window.innerWidth > 1024);
        Device.isSmallDesktop = Device.isDesktop && (window.innerWidth < 1366);

        Device.isIOS = (navigator.userAgent.indexOf("iPad") != -1) || (navigator.userAgent.indexOf("iPhone") != -1) || (navigator.userAgent.indexOf("iPod") != -1);
        Device.isChrome = !!window.chrome;
        Device.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        Device.toggleClass("html", "device__mobile", Device.isMobile);
        Device.toggleClass("html", "device__bigmobile", Device.isBigMobile);
        Device.toggleClass("html", "device__tablet", Device.isTablette);
        Device.toggleClass("html", "device__desktop", Device.isDesktop);
        Device.toggleClass("html", "device__smalldesktop", Device.isSmallDesktop);
        Device.toggleClass("html", "device__touch", Device.isTouch);
        Device.toggleClass("html", "device__ie11", Device.isIE11);

        Device.isLandscape = (window.innerWidth > window.innerHeight);
    },

    toggleClass: function (bloc, className, condition) {
        if (condition) {
            document.querySelector(bloc).classList.add(className);
        } else {
            document.querySelector(bloc).classList.remove(className);
        }
    },

    getCurrent: function () {
        if (Device.isDesktop) {
            return "desktop";
        } else if (Device.isTablette) {
            return "tablette";
        } else {
            return "mobile";
        }
    }
}

export default Device;