import React, { useEffect } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { updateGlobalStorePage } from "../Store/action";
import useApi from "../Hooks/useApi";
import { LoaderPage } from "../Components/loaders/loader";

// Components
import ComponentByJSON from "../Components/routes/component-from-json";
import Metatags from "../Components/metas-tags/meta-tags";
import Footer from "../Components/footer/footer";
import BackgroundPoints from "../modules/background-points";
import Carrieres from "../Components/carrieres/carrieres";

const TemplateCarrieres = ({ _uid }) => {
  const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });
  const { metas, flexibles_content, content } = dataFetch;

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("content-page"));
  }, [dispatch, isLoaded]);

  function renderPage() {
    return (
      <>
        <BackgroundPoints></BackgroundPoints>
        <Metatags {...metas} />
        <Carrieres {...content}></Carrieres>
        {flexibles_content &&
          flexibles_content.map((block) => ComponentByJSON(block))}

        <Footer />
      </>
    );
  }

  return isLoaded ? renderPage() : <LoaderPage />;
};

export default TemplateCarrieres;
