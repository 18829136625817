import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class BackgroundPoints extends Component {

  mounted = false;

  space = 30;
  spaceDesktop = 30;
  spaceMobile = 20;

  size = 1;
  radiusRatio = 62.5;
  fakeRadiusSquare = 80;
  posX = -1000;
  posY = -1000;
  radiant = 20;
  toleranceAlpha = 1;
  maxOpacitySpot = 0.5;

  otherSpots = [
    {
      posX: 200,
      posY: 100,
      vx: 2,
      vy: 1
    }, {
      posX: 800,
      posY: 300,
      vx: 1,
      vy: 2
    }, {
      posX: 400,
      posY: 400,
      vx: 3,
      vy: 1
    }, {
      posX: 1000,
      posY: 100,
      vx: -2,
      vy: 0
    }, {
      posX: 1400,
      posY: 700,
      vx: 0,
      vy: -1
    }, {
      posX: 1400,
      posY: 100,
      vx: -2,
      vy: -1
    }
    , {
      posX: 200,
      posY: 800,
      vx: 2,
      vy: 1
    }
  ]

  componentDidMount() {
    this.dom = ReactDOM.findDOMNode(this);
    this.canvas = this.dom.querySelector("canvas");
    this.mounted = true;

    this.html = document.querySelector("html");
    this.html.addEventListener("mousemove", this.handleMouseMoveBind);
    this.html.addEventListener("mouseleave", this.handleMouseLeaveBind);

    this.renderGraphics(-1000, -1000);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.html.removeEventListener("mousemove", this.handleMouseMoveBind);
    this.html.removeEventListener("mouseleave", this.handleMouseLeaveBind);
  }

  render() {
    return (
      <div className="background-points">
        <canvas></canvas>
      </div>
    )
  }

  handleMouseMove(e) {
    this.posX = e.offsetX;
    this.posY = e.offsetY;
  }
  handleMouseMoveBind = this.handleMouseMove.bind(this);

  handleMouseLeave(e) {
    this.posX = -1000;
    this.posY = -1000;
  }
  handleMouseLeaveBind = this.handleMouseLeave.bind(this);
  
  renderGraphics() {
    if (this.mounted) {
      let infoParent = this.canvas.parentElement.getBoundingClientRect();
      let width = infoParent.width;
      let height = infoParent.height;

      this.space = (width < 768 ? this.spaceMobile : this.spaceDesktop);

      if (this.canvas.width !== width || this.canvas.height !== height) {
        this.canvas.width = width;
        this.canvas.height = height;
      }

      let radiusSquare = this.radiusRatio * width;

      let ctx = this.canvas.getContext("2d");

      ctx.clearRect(0, 0, width, height);

      let allSpots = [];
      let nbCols = Math.round(width / this.space);
      let nbLines = Math.round(height / this.space);

      //Traitement de la matrice de points
      for (let i = 0; i < nbCols; i++) {
        allSpots[i] = [];
        let trueI = i * this.space + 5;

        for (let j = 0; j < nbLines; j++) {
          let trueJ = j * this.space + 5;

          //Distance du curseur
          let opacity = this.getOpacityFromPosition(this.posX, trueI, this.posY, trueJ, radiusSquare, 1);

          //Distance des points
          for (let k = 0; k < this.otherSpots.length; k++) {
            let tSpot = this.otherSpots[k];
            opacity += this.getOpacityFromPosition(tSpot.posX, trueI, tSpot.posY, trueJ, radiusSquare, this.maxOpacitySpot);
          }

          //Final
          opacity = opacity > 1 ? 1 : opacity;
          opacity = Math.round(opacity * this.radiant) / this.radiant;
          allSpots[i][j] = opacity;
        }
      }

      //Regroupement par Opacité (optimisation de rendu)
      let datas = {};
      for (let i = 0; i <= this.radiant; i++) {
        let value = Math.round((i / this.radiant) * this.radiant) / this.radiant;
        datas[value] = [];
      }

      for (let i = 0; i < nbCols; i++) {
        for (let j = 0; j < nbLines; j++) {
          let opacity = allSpots[i][j];
          datas[opacity].push({ x: i * this.space + 5, y: j * this.space + 5 });
        }
      }

      for (let key in datas) {
        let opacity = key;
        let spots = datas[key];

        ctx.fillStyle = "rgba(255,255,255," + opacity + ")";
        for (let i = 0; i < spots.length; i++) {
          ctx.fillRect(spots[i].x, spots[i].y, this.size, this.size);
        }
      }

      //Distance des points
      for (let k = 0; k < this.otherSpots.length; k++) {
        let tSpot = this.otherSpots[k];

        //Deplacement du point
        tSpot.posX += tSpot.vx;
        tSpot.posY += tSpot.vy;

        tSpot.posX = Math.round(tSpot.posX > width + this.fakeRadiusSquare ? -this.fakeRadiusSquare : tSpot.posX);
        tSpot.posY = Math.round(tSpot.posY > height + this.fakeRadiusSquare ? -this.fakeRadiusSquare : tSpot.posY);
      }

      requestAnimationFrame(this.renderGraphics.bind(this));
    }
  }

  getOpacityFromPosition(x, xCenter, y, yCenter, radiusSquare, maxOpacity) {
    let distance = (x - xCenter) * (x - xCenter) + (y - yCenter) * (y - yCenter);
    let ratio = this.toleranceAlpha * distance / radiusSquare;
    if (ratio <= this.toleranceAlpha) {
      return maxOpacity * (1 - ratio);
    }

    return 0;
  }
}

export default BackgroundPoints;