import React from "react";

const Presse = ({ articles, download_button }) => {
  return (
    <div className="bloc-presse">
      {articles.map((article, index) => (
        <div className="bloc-presse-article" key={index}>
          <p>{article.date}</p>
          <div className="bloc-presse-article-bas">
            <h2>{article.title}</h2>
            <div className="bloc-presse-article-download">
              <span className="icon-download"></span>
              <a
                className=""
                href={article.document ? article.document : undefined}
                download={article.document}
                target="blank"
              >
                {download_button}
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Presse;
