import Device from "./device";
import Hotspot from "./hotspot";
import SpriteAnimation from "./spriteAnimation";
import Tools from "./tools";

var Sprite3D = {
    moving: false,
    jsonUrl: "/assets/img/sprite3D/_data/frame.json",
    nbImagesTotal: 0,
    nbImagesLoaded: 0,
    nbImagesTraited: 0,
    nbImagesTotalTraited: 0,
    loadingDone: false,
    hotspots: [],
    currentStep: "step1",
    isMoving: false,

    reset: function () {
        Sprite3D.nbImagesTotal = 0;
        Sprite3D.nbImagesLoaded = 0;
        Sprite3D.nbImagesTraited = 0;
        Sprite3D.nbImagesTotalTraited = 0;
        Sprite3D.loadingDone = false;
    },

    init: function () {
        Sprite3D.main = document.querySelector(".sprite3D");
        Sprite3D.reset();

        if (Sprite3D.main) {
            Sprite3D.canvas = Sprite3D.main.querySelector(".sprite3D__canvas");
            Sprite3D.context = Sprite3D.canvas.getContext("2d");

            Sprite3D.loading = Sprite3D.main.querySelector(".sprite3D__loading");
            Sprite3D.loadingCursor = Sprite3D.main.querySelector(".sprite3D__loadingCursor");

            Sprite3D.loadImage();
        }
    },

    loadImage: function () {
        fetch(Sprite3D.jsonUrl).then(function (response) {
            return response.json();
        }).then(function (data) {
            Sprite3D.data = data;
            Sprite3D.steps = [];

            for (var key in Sprite3D.data.steps) {
                var nbFrames = Sprite3D.data.steps[key].nbFrames;

                Sprite3D.data.steps[key].sprites = {};
                Sprite3D.data.steps[key].alphas = {};
                Sprite3D.data.steps[key].final = {};

                if (!Device.isIE11 && !Device.isMobile) {
                    Sprite3D.nbImagesTotal += 2 * nbFrames;
                    Sprite3D.nbImagesTotalTraited += nbFrames;

                    for (var i = 0; i < nbFrames; i++) {
                        var imageAlpha = new Image();
                        imageAlpha.step = key;
                        imageAlpha.index = i;
                        imageAlpha.onload = function () {
                            Sprite3D.data.steps[this.step].alphas[this.index] = this;
                            Sprite3D.updateLoad();
                        }
                        imageAlpha.onerror = function () {
                            Sprite3D.updateLoad();
                        }
                        imageAlpha.src = data.url + key + "/" + "alpha/" + Tools.ajustIndex(i, nbFrames) + ".png";

                        var imageSprite = new Image();
                        imageSprite.step = key;
                        imageSprite.index = i;
                        imageSprite.onload = function () {
                            Sprite3D.data.steps[this.step].sprites[this.index] = this;
                            Sprite3D.updateLoad();
                        }
                        imageSprite.onerror = function () {
                            Sprite3D.updateLoad();
                        }
                        imageSprite.src = data.url + key + "/" + "sprite_" + Device.getCurrent() + "/" + Tools.ajustIndex(i, nbFrames) + ".jpg";
                    }
                }
                else {
                    Sprite3D.nbImagesTotal += nbFrames;

                    for (var i = 0; i < nbFrames; i++) {
                        var imageSprite = new Image();
                        imageSprite.step = key;
                        imageSprite.index = i;
                        imageSprite.onload = function () {
                            Sprite3D.data.steps[this.step].final[this.index] = this;
                            Sprite3D.data.steps[this.step].final[this.index].typeImage = "img";
                            Sprite3D.updateLoad();
                        }
                        imageSprite.onerror = function () {
                            Sprite3D.updateLoad();
                        }

                        if (Device.isIE11) {
                            imageSprite.src = data.url + key + "/" + "sprite_ie11/" + Tools.ajustIndex(i, nbFrames) + ".png";
                        }
                        else {
                            imageSprite.src = data.url + key + "/" + "sprite_" + Device.getCurrent() + "/" + Tools.ajustIndex(i, nbFrames) + ".png";
                        }
                    }
                }
            }
        });


    },

    updateLoad: function () {
        Sprite3D.nbImagesLoaded++;
        Sprite3D.state = 100 * (Sprite3D.nbImagesLoaded + Sprite3D.nbImagesTraited) / (Sprite3D.nbImagesTotal + Sprite3D.nbImagesTotalTraited);
        Sprite3D.loadingCursor.style.width = "calc(" + Sprite3D.state + "% - 6px)";

        console.log(Sprite3D.nbImagesLoaded, Sprite3D.nbImagesTotal);

        if (Sprite3D.nbImagesLoaded == Sprite3D.nbImagesTotal && !Sprite3D.loadingDone) {
            if (!Device.isIE11 && !Device.isMobile) {
                Sprite3D.generateSprite();
            }
            else {
                Sprite3D.endLoad();
            }
        }
    },

    updateTraitement: function () {
        Sprite3D.state = 100 * (Sprite3D.nbImagesLoaded + Sprite3D.nbImagesTraited) / (Sprite3D.nbImagesTotal + Sprite3D.nbImagesTotalTraited);
        Sprite3D.loadingCursor.style.width = "calc(" + Sprite3D.state + "% - 6px)";

        if (Sprite3D.nbImagesTraited == Sprite3D.nbImagesTotalTraited && !Sprite3D.loadingDone) {
            Sprite3D.endLoad();
        }
    },

    endLoad: function () {
        Sprite3D.loadingDone = true;
        Sprite3D.loading.classList.add("sprite3D__loading--done");
        Sprite3D.render();
        Sprite3D.setHotspots();
        Sprite3D.setEvents();
    },

    generateSprite: function (callback) {
        var index = 0;
        for (var step in Sprite3D.data.steps) {
            for (var frame in Sprite3D.data.steps[step].sprites) {
                Sprite3D.generateOneSprite(step, frame, index);
            }
        }
    },

    generateOneSprite: function (step, frame, index) {
        setTimeout(function () {
            var canvasSprite = Sprite3D.createCanvas("animationSprite__canvasBufferAlpha", 1920, 1080);
            var canvasSpriteContext = canvasSprite.getContext("2d");

            var canvasAlpha = Sprite3D.createCanvas("animationSprite__canvasBufferAlpha", 1920, 1080);
            var canvasAlphaContext = canvasAlpha.getContext("2d");

            var imgSprite = Sprite3D.data.steps[step].sprites[frame];
            var imgAlpha = Sprite3D.data.steps[step].alphas[frame];

            var spriteWidth = Sprite3D.data.steps[step].width;
            var spriteHeight = Sprite3D.data.steps[step].height;

            canvasAlpha.width = canvasSprite.width = spriteWidth;
            canvasAlpha.height = canvasSprite.height = spriteHeight;

            canvasSpriteContext.drawImage(imgSprite, 0, 0, imgSprite.width, imgSprite.height, 0, 0, spriteWidth, spriteHeight);
            canvasAlphaContext.drawImage(imgAlpha, 0, 0, imgAlpha.width, imgAlpha.height, 0, 0, spriteWidth, spriteHeight);

            var imageSprite = canvasSpriteContext.getImageData(0, 0, spriteWidth, spriteHeight);
            var imageSpriteData = imageSprite.data;
            var imageAlphaData = canvasAlphaContext.getImageData(0, 0, spriteWidth, spriteHeight).data;

            for (var i = 3, len = imageSpriteData.length; i < len; i = i + 4) {
                imageSpriteData[i] = imageAlphaData[i - 1];
            }

            Sprite3D.data.steps[step].final[frame] = imageSprite;
            Sprite3D.data.steps[step].final[frame].typeImage = "imagedata";

            Sprite3D.nbImagesTraited++;
            Sprite3D.updateTraitement();
        }, index * 100);
    },

    createCanvas: function (className, width, height) {
        var canvas = document.createElement("canvas");
        canvas.className = className;
        canvas.width = width;
        canvas.height = height;
        return canvas;
    },

    render: function (t) {
        requestAnimationFrame(Sprite3D.render);
        var infosSprite = null;

        if (!Sprite3D.isMoving) {
            if (t == undefined) {
                t = 0;
            }

            Sprite3D.canvas.width += 0;

            if (Sprite3D.animation) {
                infosSprite = Sprite3D.animation.render();
                var currentAngle = -30 * Sprite3D.animation.currentPercent + 15;
                Sprite3D.animation.curseur.style.transform = "translate(-50%,-50%) rotate(" + currentAngle + "deg)";
            }

        }

        for (var i = 0; i < Sprite3D.hotspots.length; i++) {
            Sprite3D.hotspots[i].render(Sprite3D.currentStep, Sprite3D.animation.nextFrame, t, infosSprite);
        }
    },

    setHotspots: function () {
        for (var key in Sprite3D.data.steps) {
            for (var i = 0; i < Sprite3D.data.steps[key].hotspots.length; i++) {
                Sprite3D.hotspots.push(new Hotspot(Sprite3D.data.steps[key].hotspots[i], key, Sprite3D, Sprite3D.data.steps[key].nbFrames));
            }
        }
    },

    setEvents: function () {
        //Event du bouton "Prev Step"
        Sprite3D.btnPrev = Sprite3D.main.querySelector(".sprite3D__prev");
        Sprite3D.btnPrev.addEventListener("click", function () {
            Sprite3D.throwStep("prev", Sprite3D.currentStep);
        });

        //Event du bouton "Next Step"
        Sprite3D.btnNext = Sprite3D.main.querySelector(".sprite3D__next");
        Sprite3D.btnNext.addEventListener("click", function () {
            Sprite3D.throwStep("next", Sprite3D.currentStep);
        });

        Sprite3D.setStep(Sprite3D.currentStep);

        //Event de la regle de rotation
        Sprite3D.animation = new SpriteAnimation();
        Sprite3D.animation.init(Sprite3D.canvas, {
            zoneEffect: Sprite3D.main.querySelector(".sprite3D__cursor"),
            zoneParent: Sprite3D.main.querySelector(".sprite3D__slider"),
            loop: false,
            startFrame: "start",
            clickOnParent: false,
            vitesseFactor: 1
        });
        Sprite3D.animation.loadBijou(Sprite3D.currentStep);
        Sprite3D.animation.slider = Sprite3D.main.querySelector(".sprite3D__slider");
        Sprite3D.animation.curseur = Sprite3D.main.querySelector(".sprite3D__cursor");
        Sprite3D.animation.curseur.classList.add("univers1__cursor");

        //Event du click sur Hotspot
        Sprite3D.canvas.addEventListener("mousemove", function (e) {
            var indexHotSpot = Sprite3D.checkDistanceWithHotSpot(e);

            if (indexHotSpot >= 0) {
                Sprite3D.main.classList.add("sprite3D--withPointer");
            } else {
                Sprite3D.main.classList.remove("sprite3D--withPointer");
            }
        });

        Sprite3D.canvas.addEventListener("click", function (e) {
            var indexHotSpot = Sprite3D.checkDistanceWithHotSpot(e);

            if (indexHotSpot >= 0) {
                for (var i = 0; i < Sprite3D.hotspots.length; i++) {
                    Sprite3D.hotspots[i].selected = false;
                }
                Sprite3D.hotspots[indexHotSpot].selected = true;
            }
        });
    },

    checkDistanceWithHotSpot: function (e) {
        var posX = Tools.getPage(e, "X");
        var posY = Tools.getPage(e, "Y");
        var currentIndex = -1;

        for (var i = 0; i < Sprite3D.hotspots.length; i++) {
            if (Sprite3D.hotspots[i].visible) {
                var h = Sprite3D.hotspots[i]
                if (Math.sqrt((posX - h.trueX) * (posX - h.trueX) + (posY - h.trueY) * (posY - h.trueY)) < h.trueWidth) {
                    currentIndex = i;
                    break;
                }
            }
        }

        return currentIndex;
    },

    readAnimation: function (step, sens, index, callback) {
        var nbFrames = Sprite3D.data.steps[step].nbFrames;

        if (sens == "normal" && index < nbFrames - 1) {
            requestAnimationFrame(function () {
                Sprite3D.readAnimation(step, sens, index + 1, callback)
            });
        }
        else if (sens == "reverse" && index > 0) {
            requestAnimationFrame(function () {
                Sprite3D.readAnimation(step, sens, index - 1, callback)
            });
        }
        else if (callback) {
            callback();
        }

        if (Sprite3D.isMoving) {
            Sprite3D.animation.renderImage(Sprite3D.data.steps[step], index);
        }
    },

    throwStep: function (sens, step) {
        if (sens == "next" && step == "step1") {
            Sprite3D.changeStep("step1", "step2")
        } else if (sens == "prev" && step == "step2") {
            Sprite3D.changeStep("step2", "step1")
        } else if (sens == "next" && step == "step2") {
            Sprite3D.changeStep("step2", "step3")
        } else if (sens == "prev" && step == "step3") {
            Sprite3D.changeStep("step3", "step2")
        }
    },

    setStep: function (step) {
        Sprite3D.btnPrev.classList.remove("sprite3D__btnVisible");
        Sprite3D.btnNext.classList.remove("sprite3D__btnVisible");
        if (step == "step1") {
            Sprite3D.btnNext.classList.add("sprite3D__btnVisible");

        } else if (step == "step2") {
            Sprite3D.btnPrev.classList.add("sprite3D__btnVisible");
            Sprite3D.btnNext.classList.add("sprite3D__btnVisible");
        }
        else {
            Sprite3D.btnPrev.classList.add("sprite3D__btnVisible");
        }
    },

    changeStep: function (lastStep, step) {
        for (var i = 0; i < Sprite3D.hotspots.length; i++) {
            Sprite3D.hotspots[i].selected = false;
        }

        Sprite3D.isMoving = true;
        Sprite3D.btnPrev.classList.remove("sprite3D__btnVisible");
        Sprite3D.btnNext.classList.remove("sprite3D__btnVisible");
        if (step == "step1") {
            Sprite3D.readAnimation("step2", "reverse", Sprite3D.data.steps["step2"].nbFrames - 1, function () {
                Sprite3D.currentStep = "step1";
                Sprite3D.readAnimation("step1", "reverse", Sprite3D.data.steps["step1"].nbFrames - 1, function () {
                    Sprite3D.animation.slider.style.display = "block";
                    Sprite3D.isMoving = false;
                    Sprite3D.setStep(Sprite3D.currentStep);
                });
            });
        } else if (step == "step2" && lastStep == "step1") {
            Sprite3D.animation.slider.style.display = "none";
            Sprite3D.readAnimation("step1", "normal", Sprite3D.animation.nextFrame, function () {
                Sprite3D.currentStep = "step2";
                Sprite3D.readAnimation("step2", "normal", 0, function () {
                    Sprite3D.isMoving = false;
                    Sprite3D.setStep(Sprite3D.currentStep);
                });
            });
        } else if (step == "step2" && lastStep == "step3") {
            Sprite3D.animation.slider.style.display = "none";
            Sprite3D.readAnimation("step3", "reverse", Sprite3D.data.steps["step3"].nbFrames - 1, function () {
                Sprite3D.currentStep = "step2";
                Sprite3D.readAnimation("step2", "normal", 0, function () {
                    Sprite3D.isMoving = false;
                    Sprite3D.setStep(Sprite3D.currentStep);
                });
            });
        } else {
            Sprite3D.animation.slider.style.display = "none";
            Sprite3D.readAnimation("step2", "reverse", Sprite3D.data.steps["step3"].nbFrames - 1, function () {
                Sprite3D.currentStep = "step3";
                Sprite3D.readAnimation("step3", "normal", 0, function () {
                    Sprite3D.isMoving = false;
                    Sprite3D.setStep(Sprite3D.currentStep);
                });
            });
        }
    }
}

export default Sprite3D;