import React, { useState } from "react";
import Sprite3D from "./sprite3D/sprite3D";

const FlexibleSprite3D = ({ background, title, cta, hotspots }) => {

    const [isOpen, setOpen] = useState(false);

    const openPopin = () => {
        setOpen(true);
        Sprite3D.init();
    }

    const closePopin = () => {
        setOpen(false);
    }

    return (
        <>
            <div className={`flexible-sprite3D layout_module flexible-${background}`}>
                <div className="flexible-sprite3D_content">
                    <div className="flexible-sprite3D_titre"><h2><span dangerouslySetInnerHTML={{ __html: title }}></span></h2></div>
                    <div className="flexible-sprite3D_lien"><button onClick={() => openPopin()} className="flexible-sprite3D_btn open_popin">{cta}</button></div>
                    <div className="flexible-sprite3D_image"><button onClick={() => openPopin()} className="open_popin"><img src="/assets/img/produit-01.png" alt="" /></button></div>
                </div>
            </div>
            <div className={`flexible-sprite3D_popin ${isOpen ? "flexible-sprite3D_popin--open" : ""}`}>
                <div className="flexible-sprite3D_popin_header">
                    <div className="flexible-sprite3D_popin_logo"><img src="/assets/img/logo-white.svg" alt="Blue Solutions" /></div>
                    <button className="flexible-sprite3D_popin_close" onClick={() => closePopin()}>
                        <div className="flexible-sprite3D_popin_close_croix">
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                </div>
                <div className="popin_content sprite3D">
                    <div className="sprite3D__object">
                        <canvas className="sprite3D__canvas"></canvas>

                        <button className="sprite3D__prev"></button>
                        <button className="sprite3D__next"></button>

                        <div className="sprite3D__slider" data-element="spriteSlider">
                            <div className="sprite3D__cursor" data-element="spriteSlider"></div>
                        </div>

                    </div>
                    {
                        hotspots && <ul className="sprite3D__hotSpotList">
                            {
                                hotspots.map((spot, index) => {
                                    return (
                                        <li key={index} className="sprite3D__hotSpotItem" data-id={spot.number}>
                                            <button className="sprite3D__hotSpotLink">
                                                <p className="sprite3D__hotSpotTitre">{spot.title}</p>
                                                <p className="sprite3D__hotSpotDescription">{spot.description}</p>
                                            </button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }

                    <div className="sprite3D__loading">
                        <div className="sprite3D__loadingBarre">
                            <div className="sprite3D__loadingCursor"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FlexibleSprite3D;