import React from "react";

const FlexibleMargin = ({ background, type }) => {
  return (
    <div
      className={`flexible-margin layout_module flexible-${background} flexible-margin-${type}`}
    ></div>
  );
};

export default FlexibleMargin;
