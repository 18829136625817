import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateGlobalStoreLang } from "../../Store/action";

import Burgerliste from "./burgerliste";

const HeaderBurger = ({ open, onClose, items }) => {
  let dataGlobal = useSelector((state) => state.global).global;
  const { language } = dataGlobal.header;
  const { linkedin, twitter } = dataGlobal.socials;

  return (
    <div
      className={`header-burger header-burger--${open ? "active" : "close"}`}
    >
      <LanguageSwitch datas={language} onClose={onClose} />
      <button
        className="header-burger_close"
        onClick={() => onClose()}
      ></button>
      {items && <Burgerliste items={items} onClose={onClose}></Burgerliste>}
      {dataGlobal.socials && (
        <div className="header-burger-socials">
          {linkedin && (
            <a href={linkedin} target="_blank">
              <div className="header-burger-socials-linkedin"></div>
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank">
              <div className="header-burger-socials-twitter"></div>
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderBurger;

/**
 * @component Changement de langue
 */
const LanguageSwitch = ({ datas, onClose }) => {
  const langStore = useSelector((state) => state.global).lang;
  const dispatch = useDispatch();

  return (
    <div className="header_lang header-burger_lang">
      {datas.map((item, i) => (
        <div key={i} className="header_lang--wrapper">
          {i !== 0 && <span className="header_lang--sep"></span>}
          <button
            key={`lang-${i}`}
            data-lang={item.lang}
            data-lang-active={item.lang === langStore}
            className="header_lang--btn"
            type="button"
            onClick={() => {
              dispatch(updateGlobalStoreLang(item.lang));
              onClose();
            }}
          >
            {item.text}
          </button>
        </div>
      ))}
    </div>
  );
};
