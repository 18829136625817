import React, { useEffect, useState } from "react";
import useApi from "../Hooks/useApi";
import Metatags from "../Components/metas-tags/meta-tags";
import { useSelector, useDispatch } from "react-redux";
import { updateGlobalStorePage } from "../Store/action";
import BackgroundPoints from "../modules/background-points";
import AnimatedImages from "../Components/home/animated-images";
import LoaderHome from "../Components/loaders/loader-home";
import BlocVideoFull from "../Components/home/bloc-video-full";
import BlocVideoSmallTexte from "../Components/home/bloc-video-small-texte";
import ScrollManager from "../Components/home/scroll-manager";
import Footer from "../Components/footer/footer";

const TemplateHome = ({ _uid }) => {
  const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });
  const [heightBloc, setHeightBloc] = useState();

  let isReady = useSelector((state) => state.loader).isReady;

  const handleResize = () => {
    setHeightBloc(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("home"));
  }, [dispatch, isLoaded]);

  function segmenteIndication(texte) {
    let size = texte.length;
    let final = "";
    let strechStart = 50;
    let strechEnd = 130;
    let weightStart = 100;
    let weightEnd = 800;
    for (let i = 0; i < size; i++) {
      final +=
        "<span style='font-weight:" +
        (weightStart + (i / size) * (weightEnd - weightStart)) +
        ";font-stretch:" +
        (strechStart + (i / size) * (strechEnd - strechStart)) +
        "%'>" +
        texte[i] +
        "</span>";
    }
    return final;
  }

  function renderPage() {
    const { metas, content } = dataFetch;

    return (
      <>
        {metas && <Metatags {...metas} />}

        {content && (
          <>
            <ScrollManager></ScrollManager>
            <BackgroundPoints></BackgroundPoints>
            <AnimatedImages
              tolerance={0.1}
              textes={[
                {
                  percent: 0,
                  value:
                    "<p class='home__uptitle'>" +
                    content.section__battery_states[0].uptitle +
                    "</p>" +
                    content.section__battery_states[0].title,
                  profondeur: "upside",
                  position: "top",
                  classMore: "firstTitre",
                },
                {
                  percent: 0.2,
                  value: content.section__battery_states[1].title,
                  profondeur: "upside",
                  position: "right",
                  positionMobile: "rightTopMobile",
                },
                {
                  percent: 0.4,
                  value:
                    content.section__battery_states[2].title +
                    "<p class='home__texte'>" +
                    content.section__battery_states[2].text +
                    "</p>",
                  profondeur: "upside",
                  position: "left",
                },
                {
                  percent: 0.4,
                  value:
                    "<p><span class='home__number'>" +
                    content.section__battery_states[2].datas.number +
                    "</span> <span class='home__unit'>" +
                    content.section__battery_states[2].datas.unit +
                    "</span></p><p><span class='home__label'>" +
                    content.section__battery_states[2].datas.label +
                    "</span></p>",
                  profondeur: "upside",
                  profondeurMobile: "upside",
                  position: "right",
                  classMore: "withUnit",
                },
                {
                  percent: 0.6,
                  value:
                    content.section__battery_states[3].title_1 +
                    "<p class='home__texte'>" +
                    content.section__battery_states[3].text_1 +
                    "</p><p class='home__extense'>" +
                    segmenteIndication(
                      content.section__battery_states[3].indication
                    ) +
                    "</p>",
                  profondeur: "upside",
                  position: "left",
                },
                {
                  percent: 0.6,
                  value:
                    content.section__battery_states[3].title_2 +
                    "<p class='home__texte'>" +
                    content.section__battery_states[3].text_2 +
                    "</p>",
                  profondeur: "upside",
                  position: "right",
                },
                {
                  percent: 0.8,
                  value:
                    content.section__battery_states[4].title +
                    "<p class='home__texte'>" +
                    content.section__battery_states[4].text +
                    "</p>",
                  profondeur: "upside",
                  position: "left",
                },
                {
                  percent: 0.8,
                  value:
                    "<p><span class='home__label'>" +
                    content.section__battery_states[4].datas.label +
                    "</span></p><p><span class='home__number2'>" +
                    content.section__battery_states[4].datas.number +
                    "</span></p><p><span class='home__unit2'>" +
                    content.section__battery_states[4].datas.unit +
                    "</span></p>",
                  profondeur: "upside",
                  profondeurMobile: "upside",
                  position: "right",
                  classMore: "withUnit2",
                },
                {
                  percent: 1,
                  value:
                    content.section__battery_states[5].title +
                    "<p class='home__texte'>" +
                    content.section__battery_states[5].text +
                    "</p>",
                  profondeur: "upside",
                  position: "left",
                },
                {
                  percent: 1,
                  value:
                    "<p><span class='home__number3'>" +
                    content.section__battery_states[5].datas[0].number +
                    "</span></p><p><span class='home__label'>" +
                    content.section__battery_states[5].datas[0].label +
                    "</span></p><p><span class='home__number3'>" +
                    content.section__battery_states[5].datas[1].number +
                    "</span></p><p><span class='home__label'>" +
                    content.section__battery_states[5].datas[1].label +
                    "</span></p>",
                  profondeur: "upside",
                  profondeurMobile: "upside",
                  position: "right",
                  classMore: "withUnit2",
                },
              ]}
              sequence={"animation1"}
              seo={content.section__battery_states[0].seo}
              classMore={"firstBloc"}
              cta={content.cta.documentation}
            ></AnimatedImages>
            {content.section__bus && (
              <div
                className="slide slideContainer slideCenter"
                style={{ height: heightBloc }}
              >
                <BlocVideoSmallTexte
                  mediaType={content.section__bus.media_type}
                  mediaSrc={
                    content.section__bus.media_type === "image"
                      ? content.section__bus.image
                      : content.section__bus.media_type === "video"
                      ? content.section__bus.video
                      : false
                  }
                  blocDisposition={"type1"}
                  secondTitre={content.section__bus.title}
                  texte={content.section__bus.text}
                  troisiemeTitre={content.section__bus.push_text}
                  cta={content.section__bus.cta}
                ></BlocVideoSmallTexte>
              </div>
            )}
            <div className="slide slideContainer slideCenter">
              {content.ended_sections && content.ended_sections.blocks[0] && (
                <BlocVideoSmallTexte
                  mainTitre={content.ended_sections.title}
                  mediaType={content.ended_sections.blocks[0].media_type}
                  mediaSrc={
                    content.ended_sections.blocks[0].media_type === "image"
                      ? content.ended_sections.blocks[0].image
                      : content.ended_sections.blocks[0].media_type === "video"
                      ? content.ended_sections.blocks[0].video
                      : false
                  }
                  blocDisposition={"type2"}
                  secondTitre={content.ended_sections.blocks[0].title}
                  texte={content.ended_sections.blocks[0].text}
                  cta={content.ended_sections.blocks[0].cta}
                ></BlocVideoSmallTexte>
              )}
            </div>
            <AnimatedImages
              tolerance={0.5}
              apparitionOnIntersection={true}
              textes={[
                {
                  percent: 0.5,
                  value:
                    content.section__car.title +
                    "<p class='home__texte'>" +
                    content.section__car.text +
                    "</p>",
                  profondeur: "upside",
                  position: "left",
                },
                {
                  percent: 0.5,
                  value: content.section__car.push_text,
                  profondeur: "upside",
                  position: "right2",
                  classMore: "prochainement",
                },
              ]}
              classMore={"smallerBloc2"}
              sequence={"animation3"}
            ></AnimatedImages>

            <div className="slide slideContainer slideCenter">
              {content.ended_sections && content.ended_sections.blocks[1] && (
                <BlocVideoSmallTexte
                  mediaType={content.ended_sections.blocks[1].media_type}
                  mediaSrc={
                    content.ended_sections.blocks[1].media_type === "image"
                      ? content.ended_sections.blocks[1].image
                      : content.ended_sections.blocks[1].media_type === "video"
                      ? content.ended_sections.blocks[1].video
                      : false
                  }
                  blocDisposition={"type3"}
                  secondTitre={content.ended_sections.blocks[1].title}
                  texte={content.ended_sections.blocks[1].text}
                  imageSup={content.ended_sections.blocks[1].image_sup}
                  lastItem={true}
                  cta={content.ended_sections.blocks[1].cta}
                ></BlocVideoSmallTexte>
              )}
            </div>
            <div className="slide slideContainer slideMarginTop">
              {content.section__video &&
                content.section__video.video &&
                content.section__video.video.url && (
                  <BlocVideoFull
                    mediaType={content.section__video.media_type}
                    media={
                      content.section__video.media_type === "image"
                        ? content.section__video.image
                        : content.section__video.media_type === "video"
                        ? content.section__video.video
                        : false
                    }
                    textes={content.section__video.texts}
                  ></BlocVideoFull>
                )}
            </div>
            <div className="slide slideContainer slideSmall">
              <Footer isHome={true} />
            </div>
          </>
        )}
      </>
    );
  }

  return isReady && isLoaded ? renderPage() : <LoaderHome />;
};

export default TemplateHome;
