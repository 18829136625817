import React from "react";
import Wysiwyg from "../../modules/wysiwyg";

const FlexibleTextePicto = ({ background, items }) => {
  return (
    <div className={`flexible-texte-picto layout_module flexible-${background}`}>
      {
        items && <ul className={`flexible-texte-picto-liste`}>
          {
            items.map((item, index) => {
              return (
                <li className={`flexible-texte-picto-item flexible-texte-picto-item-${item.size}`} key={index}>
                  <div className="flexible-texte-picto-entete">
                    <div className="flexible-texte-picto-image"><img src={item.picto.url} alt={item.picto.alt} /></div>
                    <p className="flexible-texte-picto-titre">{item.title}</p>
                  </div>
                  <Wysiwyg className="flexible-texte-picto-texte">{item.text}</Wysiwyg>
                </li>
              )
            })
          }
        </ul>
      }
    </div>
  );
};
export default FlexibleTextePicto;
