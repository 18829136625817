import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { updateGlobalStorePage } from "../Store/action";
import useApi from "../Hooks/useApi";
import { LoaderPage } from "../Components/loaders/loader";

// Components
import ComponentByJSON from "../Components/routes/component-from-json";
import Metatags from "../Components/metas-tags/meta-tags";
import Footer from "../Components/footer/footer";
import BackgroundPoints from "../modules/background-points";
import EnTeteArticleFilter from "../modules/entete-articleFilter";
import News from "../Components/news/news";

// J'utilise un tableau qui affichera mes jobs de sur la colonne de gauche et un autre pour la colonneDroite
var colonneGauche = [];
var colonneDroite = [];
var current_year = 'ALL';

const TemplateNews = ({ _uid }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [year, setYear] = useState("ALL");
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(1);
  const [dataFetch, isLoaded] = useApi({ name: "pages", _uid, year, category, page });
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateGlobalStorePage("content-page"));
  }, [dispatch, isLoaded]);

  useEffect(() => {
    if(year != current_year){
    	setPage(1);
    }
  }, [year]);

  useEffect(() => {
    if (isLoaded && firstLoad) {
      //setFirstLoad(false);
      dataFetch.content.default_year && setYear(dataFetch.content.default_year);

      if(year != current_year){
      	colonneGauche = [];
				colonneDroite = [];
      	current_year = year;
      }

      // Complète le tableau des news à chaque call
      for (let i = 0; i < dataFetch.content.articles.length; i++) {
		    if (i % 2 === 0) {
		      colonneGauche.push(dataFetch.content.articles[i]);
		    } else {
		      colonneDroite.push(dataFetch.content.articles[i]);
		    }
		  }

    }
  }, [isLoaded]);

  function renderPage() {
    const { metas, flexibles_content, content } = dataFetch;

    return (
      <>
        <BackgroundPoints></BackgroundPoints>
        <Metatags {...metas} />
        <div className="flexible-banniere">
          <div className="flexible-banniere-media">
            <img src={content.banner} alt="" />
          </div>
          <div className="flexible-banniere-content">
            <h1>{content.title}</h1>
          </div>
        </div>
        <div className="layoutNews">
          <EnTeteArticleFilter
            setYear={setYear}
            setCategory={setCategory}
            year={year}
            years={content && content.years}
            category={category}
            categories={content && content.categories}
            bgColor={"blue"}
            {...content}
          >
            <News
            	{...content}
            	setPage={setPage}
            	colonneGauche={colonneGauche}
            	colonneDroite={colonneDroite}
            >
            </News>
          </EnTeteArticleFilter>
        </div>

        {flexibles_content &&
          flexibles_content.map((block) => ComponentByJSON(block))}

        <Footer />
      </>
    );
  }

  return isLoaded ? renderPage() : <LoaderPage />;
};

export default TemplateNews;
