import './Styles/App.scss';
import React, { useEffect } from 'react';
import ScrollToTop from './Components/ScrollToTop';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import RouteForTemplates from './Components/routes/route-for-templates';
import LoaderHome from './Components/loaders/loader-home';

// HOOKS
import useApi from './Hooks/useApi';
import { useSelector, useDispatch } from 'react-redux';
import {
	updateGlobalStoreGlobal,
	updateGlobalisLoaded,
	updateGlobalStoreRoutes,
	updateGlobalScrollLock,
	updateGlobalStoreLang,
	updateIsMobile,
	updateIsTablet,
	updateIsTabletWide,
	updateIsDesktop
} from './Store/action';

// TPL PARTS
import Header from './Components/header/header';

import ImageManager from './Components/home/imageManager';
import { Loader } from './Components/loaders/loader';
import Device from './Components/flexible/sprite3D/device';

const App = () => {
	// Global apis
	let dispatch = useDispatch();
	let isLoaded = useSelector(state => state.global).isLoaded;
	let isReady = useSelector(state => state.loader).isReady;
	let page = useSelector(state => state.page).page;
	let { mobile, tablet, tabletwide, desktop } = useSelector(state => state.breakpoint);

	Device.init();

	if (tabletwide) {
		document.querySelector("html").classList.add("device__tabletwide");
	} else {
		document.querySelector("html").classList.remove("device__tabletwide");
	}

	// API dynamiques ou via des JSON
	let [dataGlobalAPI, globalIsLoaded] = useApi({ name: 'global' });
	let [dataRoutesAPI, routesIsLoaded] = useApi({ name: 'routes' });

	// INIT SCROLL LOCK REDUCER
	useEffect(() => {
		dispatch(updateGlobalScrollLock(false));
	}, [dispatch]);

	// STORE ADD GLOBAL API
	useEffect(() => {
		dispatch(updateGlobalStoreGlobal(dataGlobalAPI));
	}, [dispatch, dataGlobalAPI])

	// STORE ADD ROUTES API
	useEffect(() => {
		dispatch(updateGlobalStoreRoutes(dataRoutesAPI));
	}, [dispatch, dataRoutesAPI])

	// STORE SET IS LOADED
	useEffect(() => {
		dispatch(updateGlobalisLoaded(routesIsLoaded && globalIsLoaded))
	}, [dispatch, routesIsLoaded, globalIsLoaded])

	// DISPATCH LANGUAGE FROM URL
	useEffect(() => {
		let langArray = window.location.href.split('/');
		langArray[3] === 'en' ? dispatch(updateGlobalStoreLang('en')) : dispatch(updateGlobalStoreLang('fr'));
		// langArray[3] === 'fr' && dispatch(updateGlobalStoreLang('fr'));
	}, [dispatch])

	//CHARGEMENT D IMAGE
	let device_string = "sprite_mobile";
	if (desktop) {
		device_string = "sprite_desktop";
	} else if (tabletwide) {
		device_string = "sprite_tablet";
	} else if (tablet) {
		device_string = "sprite_tablet";
	}

	ImageManager.addSequence({
		name: "animation1",
		fileNotAlpha: "/assets/animation/animation-01/" + device_string + "/batterie_[X].png?v=2",
		fileNotAlphaWebp: "/assets/animation/animation-01/" + device_string + "_webp/batterie_[X].webp?v=2",
		start: 0,
		end: 118,
		loop: 95,
		trueStart: 35,
		step: 6,
		position: "top"
	});

	// ImageManager.addSequence({
	// 	name: "animation2",
	// 	fileNotAlpha: "/assets/animation/animation-02/" + device_string + "/vehicule_0[X].png?v=2",
	// 	fileNotAlphaWebp: "/assets/animation/animation-02/" + device_string + "_webp/vehicule_0[X].webp?v=2",
	// 	start: 0,
	// 	trueStart: 54,
	// 	end: 54,
	// 	step: 1,
	// 	position: "center",
	// 	auto: "true"
	// });

	ImageManager.addSequence({
		name: "animation3",
		fileNotAlpha: "/assets/animation/animation-03/" + device_string + "/vehicule_0[X].png?v=3",
		fileNotAlphaWebp: "/assets/animation/animation-03/" + device_string + "_webp/vehicule_0[X].webp?v=3",
		start: 0,
		trueStart: 28,
		end: 28,
		step: 1,
		position: "center",
		auto: "true"
	});

	ImageManager.loadImages({ mobile, tablet, tabletwide, desktop });

	// DISPATCH WINDOW SIZE ON RESIZE
	useEffect(() => {
		const checkDevice = () => {
			if (window.matchMedia('(max-width:767px)').matches && !mobile) {
				dispatch(updateIsMobile(true));
				dispatch(updateIsTablet(false));
				dispatch(updateIsTabletWide(false));
				dispatch(updateIsDesktop(false));
			}
			if (window.matchMedia('(min-width:768px) and (max-width:1023px)').matches && !tablet) {
				dispatch(updateIsMobile(false));
				dispatch(updateIsTablet(true));
				dispatch(updateIsTabletWide(false));
				dispatch(updateIsDesktop(false))
			}
			if (window.matchMedia('(min-width:1024px) and (max-width: 1099px)').matches && !tabletwide) {
				dispatch(updateIsMobile(false));
				dispatch(updateIsTablet(false));
				dispatch(updateIsTabletWide(true));
				dispatch(updateIsDesktop(false))
			}
			if (window.matchMedia('(min-width:1100px)').matches && !desktop) {
				dispatch(updateIsMobile(false));
				dispatch(updateIsTablet(false));
				dispatch(updateIsTabletWide(false));
				dispatch(updateIsDesktop(true))
			}
		}

		checkDevice();

		window.addEventListener('resize', checkDevice);

		// clean listener
		return () => {
			window.removeEventListener('resize', checkDevice);
		}
	}, [dispatch, desktop, tabletwide, mobile, tablet]);

	function renderPage() {
		return (
			<Router>
				<ScrollToTop>
					<Header />

					<Switch>
						{dataRoutesAPI && dataRoutesAPI.map((route) => {
							return <RouteForTemplates key={route._uid} {...route} datas={{}} />;
						})}
					</Switch>
				</ScrollToTop>
			</Router>
		);
	}

	function renderContainer() {
		return (
			<div className={`app ${page ? page : ""} ${isReady ? "app--ready" : ""}`}>
				{
					isReady ? renderPage() : <LoaderHome />
				}
			</div>
		)
	}

	return isLoaded ? renderContainer() : <Loader />;
}

export default App;
