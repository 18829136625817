import {
    GLOBAL_UPDATE_ANCHORS,
    GLOBAL_UPDATE_ANCHORS_VISIBLE
} from "../action";

let initialState = {
    anchors: {},
    statut: "none"
};

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function AnchorReducer(state = initialState, action) {
    switch (action.type) {
        case GLOBAL_UPDATE_ANCHORS:
            return { ...state, anchors: action.value };
        case GLOBAL_UPDATE_ANCHORS_VISIBLE:
            return { ...state, statut: action.value };
        default:
            return state;
    }
}
