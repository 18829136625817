import React from "react";
import Filter from "../Components/filter/filter";

const EnTeteArticleFilter = ({
  children,
  setYear,
  setCategory,
  year,
  years,
  category,
  categories,
  bgColor,
  dictionnary,
  bigPadding,
}) => {
  return (
    <div className="entete-article-m0">
      <Filter
        dictionnary={dictionnary}
        setYear={setYear}
        year={year}
        years={years}
        setCategory={setCategory}
        category={category}
        categories={categories}
        bigPadding={bigPadding}
      ></Filter>
      <div className={`entete-article-box entete-article-box-${bgColor}`}>
        {children}
      </div>
    </div>
  );
};

export default EnTeteArticleFilter;
