import {
  LOADER_IS_READY,
  LOADER_UPDATE_STATUS,
  LOADER_UPDATE_IMAGES,
} from "../action";

let initialState = {
  isReady: false,
  status: 0,
  images: {},
};

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case LOADER_IS_READY:
      return { ...state, isReady: action.value };
    case LOADER_UPDATE_STATUS:
      return { ...state, status: action.value };
    case LOADER_UPDATE_IMAGES:
      return { ...state, images: action.value };
    default:
      return state;
  }
}
