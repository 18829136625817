import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CallbackForm from './callback-form';
import ContactForm from './contact-form';

const StickyBar = ({ progress, isHome }) => {

    let { sticky_elements } = useSelector(state => state.global).global;

    const [openList, setOpenList] = useState(false);
    const [formType, setFormType] = useState(false);
    const html = document.querySelector("html");

    const handleClick = (type) => {
        type = type === formType ? false : type;
        setFormType(type);

        if(type === false){
            html.classList.remove("withContact");
        } else {
            html.classList.add("withContact");
        }
    }

    const closeForm = () => {
        setFormType(false);
        html.classList.remove("withContact");
    }

    const handleOpenList = () => {
        setOpenList(!openList);
    }

    function renderComponent() {
        return (
            <div className={`stickyBar ${isHome ? "stickyBar--home" : ""} ${progress > 0.1 ? "stickyBar--blue" : ""}`}>
                <div className="stickyBar_list" data-open={openList}>
                    {sticky_elements.desktop.map(({ label, type, document }, index) => type !== 'download' ?
                        (
                            <Button key={`stickybtn-${index}`} label={label} type={type} handleClick={handleClick} />
                        ) : (
                            <Download key={`stickybtn-${index}`} label={label} document={document} />
                        ))}
                </div>

                <button type="button" className="stickyBar_btn mobileOnly" onClick={handleOpenList} data-color=""></button>

                <CallbackForm open={formType === "callback"} close={() => closeForm()} />
                <ContactForm open={formType === "contact"} close={() => closeForm()} />
            </div>
        )
    }
    return sticky_elements && renderComponent();
}
export default StickyBar;


const Button = ({ label, type, handleClick }) => {

    // const [color, setColor] = useState(false);
    const renderMarkup = (markup) => {
        return { __html: markup };
    };

    return (
        <button type="button" className="stickyBar_btn" data-type={type} onClick={() => handleClick(type)} data-color="">
            <span className="stickyBar_btn--label" dangerouslySetInnerHTML={renderMarkup(label)}></span>
        </button>
    )
}

const Download = ({ label, document }) => {

    // const [color, setColor] = useState(false);
    const renderMarkup = (markup) => {
        return { __html: markup };
    };

    return (
        <a href={document} className="stickyBar_btn download" rel="noopener noreferrer" target="_blank" download data-color="">
            <div className={"stickyBar_btn--box"}>
                <span className="stickyBar_btn--label" dangerouslySetInnerHTML={renderMarkup(label)}></span>
                <span className="stickyBar_btn--picto icon-download"></span>
            </div>
        </a>
    )
}