import { WidgetInstance } from "friendly-challenge";
import React, { useEffect, useRef } from "react";

export const FriendlyCaptcha = ({ setDisabled, setSolution, siteKey }) => {
  
    const container = useRef()
    const widget = useRef()

    useEffect(() => {
        if (!widget.current && container.current) {
            widget.current = new WidgetInstance(container.current, {
                startMode: 'none',
                doneCallback: doneCallback,
                errorCallback: errorCallback,
                puzzleEndpoint: "https://eu-api.friendlycaptcha.eu/api/v1/puzzle",
                sitekey: siteKey
            })
        }

        return () => {
            if (widget.current != undefined) widget.current.reset()
        }
    }, [container])

    const doneCallback = (solution) => {
        setSolution(solution)
        setDisabled(false)
    }
    
    const errorCallback = (err) => {
        console.log(err)
    }
    
    return (
      <div
        ref={container}
        className="frc-captcha"
        data-start="none"
        data-sitekey={`${siteKey}`}
        data-puzzle-endpoint="https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
      />
    )
  }