import React from "react";
import Link2 from "../../modules/link2";
import Wysiwyg from "../../modules/wysiwyg";

const FlexibleTexteImage = ({
  background,
  cta,
  grid,
  image,
  orientation,
  title,
  text,
}) => {
  return (
    <div
      className={`flexible-text-image layout_module flexible-${background} flexible-text-image--${grid} flexible-text-image--${orientation}`}
    >
      <div className="flexible-text-image-content">
        {title && <h3 className="flexible-text-image-title">{title}</h3>}
        <Wysiwyg className="flexible-text-image-texte">{text}</Wysiwyg>
        {cta && (
          <p
            className={`flexible-text-image-cta cta cta--${cta.type} cta--${cta.type}-${background}`}
          >
            <Link2 to={cta.url} target={cta.target} type={cta.type}>
              {cta.title}
            </Link2>
          </p>
        )}
      </div>
      <div className="flexible-text-image-media">
        <img src={image.url} alt={image.alt} />
      </div>
    </div>
  );
};
export default FlexibleTexteImage;
