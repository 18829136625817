import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import BurgerListeChildren from "./burgerlistechildren";

const BurgerListe = ({ items, onClose }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  const [isHovered, setIsHovered] = useState(-1);
  return (
    <ul className="header-burger_liste">
      {items.map((item, index) => {
        return (
          <>
            {isTabletOrMobile && (
              <li key={index} className="header-burger_item">
                <h3
                  onClick={() => {
                    setIsHovered(index);
                  }}
                  className={isHovered === index ? "font-orange" : null}
                >
                  {item.text}
                </h3>
                {item.children && (
                  <BurgerListeChildren
                    item={item}
                    onClose={onClose}
                    isHovered={isHovered === index}
                    setIsHovered={setIsHovered}
                  ></BurgerListeChildren>
                )}
              </li>
            )}
            {isDesktop && (
              <li
                key={index}
                className="header-burger_item"
                onMouseLeave={() => setIsHovered(-1)}
              >
                <NavLink
                  to={item.url}
                  onClick={() => {
                    setIsHovered(index);
                    onClose();
                  }}
                  onMouseEnter={() => setIsHovered(index)}
                >
                  {item.text}
                </NavLink>
                {item.children && (
                  <BurgerListeChildren
                    item={item}
                    onClose={onClose}
                    isHovered={isHovered === index}
                    setIsHovered={setIsHovered}
                  ></BurgerListeChildren>
                )}
              </li>
            )}
          </>
        );
      })}
    </ul>
  );
};
export default BurgerListe;
