import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const HeaderNav = ({ visible }) => {
  let dataGlobal = useSelector((state) => state.global).global;
  let lang = useSelector((state) => state.global).lang;
  const { logo } = dataGlobal.header;

  let anchors = useSelector((state) => state.anchor).anchors;
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div
        className={`header-nav header-nav--${visible ? "visible" : "close"}`}
      >
        <Link
          to={`/${lang === "fr" ? "" : lang + "/"}`}
          className="header-nav_logo"
        >
          {logo && logo.src_white && (
            <img src={logo.src_white.url} alt={logo.src_white.alt} />
          )}
        </Link>
        {anchors && (
          <ul className="header-nav_anchor">
            {Object.entries(anchors).map((anc, index) => {
              return (
                <li className="header-nav_anchor_item" key={anc}>
                  <a href={`#${anc[0]}`}>{anc[1]}</a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div
        className={`header-nav-goTop--${visible ? "visible" : "close"}`}
        onClick={() => {
          scrollTop();
        }}
      ></div>
    </>
  );
};

export default HeaderNav;
