import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "scroll-behavior-polyfill";

// Redux
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { globalReducer } from './Store/Reducers/globalReducer';
import { pageReducer } from './Store/Reducers/pageReducer';
import { breakpointReducer } from './Store/Reducers/breakpointReducer';
import { loaderReducer } from './Store/Reducers/loaderReducer';
import { AnchorReducer } from './Store/Reducers/anchorsReducer';

if (!!window.MSInputMethodContext && !!document.documentMode) {
    document.querySelector("html").classList.add("device__ie");
    // document.querySelector("body").addEventListener("mousewheel", function (event) {
    //     event.preventDefault();
    //     var wd = event.wheelDelta;
    //     var csp = window.pageYOffset;
    //     window.scrollTo(0, csp - wd);
    // });
}

const reducer = combineReducers({
    global: globalReducer,
    page: pageReducer,
    breakpoint: breakpointReducer,
    loader: loaderReducer,
    anchor: AnchorReducer
})

const store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
