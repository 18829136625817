import React from "react";

const FlexibleImage = ({ background, image, size, title }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  const breakpointTablet = 1023;
  const breakpointMobile = 768;

  return (
    <div
      className={`flexible-image flexible-image-${size} layout_module flexible-${background}`}
    >
      {width > breakpointTablet ? (
        <img src={image.desktop.url} alt={image.desktop.alt} />
      ) : width > breakpointMobile ? (
        <img src={image.tablet.url} alt={image.tablet.alt} />
      ) : (
        <img src={image.mobile.url} alt={image.mobile.alt} />
      )}
      {title && <p className="flexible-image_texte">{title}</p>}
    </div>
  );
};
export default FlexibleImage;
