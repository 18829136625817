import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Wysiwyg from "../../modules/wysiwyg";

const FlexibleGalerie = ({ background, section_title, images, grid }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div
      className={`flexible-galerie flexible-galerie-slider layout_module flexible-${background}`}
    >
      {section_title && (
        <p className="flexible-galerie-titre">{section_title}</p>
      )}
      <div className="flexible-galerie-desktop">
        {images && (
          <ul
            className={`flexible-galerie-liste flexible-galerie-liste-${grid}`}
          >
            {images.map((img, index) => {
              return (
                <li className="flexible-galerie-item" key={index}>
                  <div className="flexible-galerie-item-image">
                    <img src={img.image.url} alt={img.image.alt} />
                  </div>
                  <p className="flexible-galerie-item-titre">{img.title}</p>
                  <p className="flexible-galerie-item-subtitle">
                    {img.subtitle}
                  </p>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className="flexible-galerie-mobile">
        {images && (
          <Slider {...settings}>
            {images.map((slide, index) => {
              return (
                <div key={index}>
                  <div className="flexible-galerie-slider-item">
                    <div className="flexible-galerie-slider-content">
                      {slide.title && (
                        <h3 className="flexible-galerie-slider-title">
                          {slide.title}
                        </h3>
                      )}
                      <Wysiwyg className="flexible-galerie-slider-texte">
                        {slide.subtitle}
                      </Wysiwyg>
                    </div>
                    <div className="flexible-galerie-slider-media">
                      <img src={slide.image.url} alt={slide.image.alt} />
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
};
export default FlexibleGalerie;
