import React, { useEffect, useState } from "react";

const ScrollManager = () => {
  let html;
  let body;
  let parent;

  let isMoving = false;
  let currentSlide = 0;
  let sens = 0;
  let slides = [];

  let touchY = 0;

  const [stateScroll, setStateScroll] = useState("firstSlide");

  const scroll = (e) => {
    if (!isMoving) {
      isMoving = true;

      if (parent.scrollTop === 0) {
        currentSlide = 0;
        document
          .querySelectorAll("html.withSlide .slideContainer")
          .forEach((item) => (item.scrollTop = 0));
      }

      if (e) {
        sens = e.deltaY > 0 ? 1 : -1;
        applyScroll(sens);
      } else {
        setScrollTop(false, parent.scrollTop);
      }
    }
  };

  const applyScroll = (sens) => {
    if (needScrollInside(sens)) {
      enableAnimation();
      return;
    }

    currentSlide += sens;

    if (currentSlide < 0) {
      currentSlide = 0;
      sens = 0;
      enableAnimation();
    } else if (currentSlide >= slides.length) {
      currentSlide = slides.length - 1;
      sens = 0;
      enableAnimation();
    } else {
      setScrollTop(true, parent.scrollTop);
    }
  };

  const handleScroll = (e) => {
    if (
      !html.classList.contains("withContact") &&
      !html.classList.contains("withMenu")
    ) {
      scroll(e);
    }
  };

  const handleResize = (e) => {
    scroll();
  };

  const handleTouchStart = (e) => {
    touchY = e.changedTouches[0].pageY;
  };

  const handleTouchEnd = (e) => {
    if (!isMoving) {
      isMoving = true;

      if (e.target.classList.contains("btnScroll")) {
        currentSlide++;
        sens = 1;
        setScrollTop(true, parent.scrollTop);
      } else {
        let endY = e.changedTouches[0].pageY;
        let diff = endY - touchY;
        if (Math.abs(diff) > 50) {
          sens = diff < 0 ? 1 : -1;
          applyScroll(sens);
        } else {
          enableAnimation();
        }
      }
    }
  };

  const handleClick = (e) => {
    if (e.target.classList.contains("btnScroll")) {
      if (!isMoving) {
        isMoving = true;
        currentSlide++;
        sens = 1;
        setScrollTop(true, parent.scrollTop);
      }
    }

    if (e.target.classList.contains("btnBackToTop")) {
      if (!isMoving) {
        isMoving = true;
        currentSlide = 0;
        sens = -1;
        setScrollTop(true, parent.scrollTop);
      }
    }
  };

  useEffect(() => {
    html = document.querySelector("html");
    body = document.querySelector("body");

    parent = html;

    if (navigator.userAgent.toLowerCase().indexOf("edge") > -1) {
      parent = body;
    }

    html.classList.add("withSlide");
    html.addEventListener("wheel", handleScroll);
    html.addEventListener("touchstart", handleTouchStart);
    html.addEventListener("touchend", handleTouchEnd);
    html.addEventListener("click", handleClick);
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    slides = setSlides(document.querySelectorAll(".slide"));

    return () => {
      html.classList.remove("withSlide");
      html.removeEventListener("wheel", handleScroll);
      html.removeEventListener("touchstart", handleTouchStart);
      html.removeEventListener("touchend", handleTouchEnd);
      html.removeEventListener("click", handleClick);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  const setScrollTop = (needDuration, currentScrollTop) => {
    //let currentScrollTop = parent.scrollTop;
    let item = slides[currentSlide];
    let scrollTop = item.slide.offsetTop + item.decalage;

    if (needDuration && sens !== 0) {
      let nextScroll = currentScrollTop + 40 * sens;

      if (
        (sens > 0 && scrollTop < nextScroll) ||
        (sens < 0 && scrollTop > nextScroll)
      ) {
        parent.scrollTop = scrollTop;
        enableAnimation();
        sens = 0;
      } else {
        parent.scrollTop = nextScroll;
        setTimeout(() => setScrollTop(true, nextScroll), 20);
      }
    } else {
      parent.scrollTop = scrollTop;
      enableAnimation();
      sens = 0;
    }
  };

  const setSlides = (slides) => {
    let tab = [];

    slides.forEach((slide) => {
      let steps = 1;
      let duration = 0;
      if (slide.dataset.step && slide.dataset.duration) {
        steps = slide.dataset.step;
        duration = slide.dataset.duration;
      }

      for (let i = 0; i < steps; i++) {
        tab.push({
          slide,
          decalage: Math.floor(i * (steps > 1 ? duration / (steps - 1) : 0)),
        });
      }
    });

    return tab;
  };

  const needScrollInside = (sens) => {
    let item = slides[currentSlide].slide;
    return false;

    if (
      item.clientHeight < item.scrollHeight &&
      ((sens === -1 && item.scrollTop > 0) ||
        (sens === 1 && item.scrollTop + item.clientHeight < item.scrollHeight))
    ) {
      return true;
    }

    return false;
  };

  const enableAnimation = (isInstant) => {
    if (currentSlide >= slides.length - 2) {
      setStateScroll("lastSlide");
    } else if (currentSlide === 0) {
      setStateScroll("firstSlide");
    } else {
      setStateScroll("otherSlide");
    }

    if (isInstant) {
      isMoving = false;
    } else {
      setTimeout(() => (isMoving = false), 750);
    }
  };

  return (
    <div className="scroll-manager">
      <div className={`scroll-manager-scroll btnScroll ${stateScroll}`}>
        <span>scroll</span>
      </div>
      <div
        className={`header-nav-goTop--${stateScroll} btnBackToTop`}></div>
    </div>
  );
};

export default ScrollManager;
