import React, { useEffect, useState } from 'react';

const SelectBox = ({ register, classNameButton, classNameItem, classNameList, onChange, options, nameInput, defaultLabel, reset = null, setReset = null  }) => {

    const [isOpen, setOpen] = useState(false);
    const [valueDefault, setValueDefault] = useState(defaultLabel ? defaultLabel : options.length > 0 ? options[0][1] : null);

    const toggleMenu = (e) => {
        e.preventDefault();
        setOpen(!isOpen);
    }

    const changeSelect = (e) => {
        let currentValue = e.target.dataset.id;
        let currentText = e.target.dataset.texte;
        setValueDefault(currentText);
        setOpen(false);

        if (onChange) {
            onChange(currentValue, currentText);
        }
    }

    const resetSiblings = () => {
    	if(setReset){
    		if(nameInput == 'press_year'){
    			setReset('select__category');
    		} else if(nameInput == 'press_category'){
    			setReset('select__year');
    		}
    	}
    }

    useEffect(() => {
        if(reset){
        	setOpen(false);
        }
    }, [reset]);

    return (
        (options && options.length > 0) ? (
            <div className={`selectBox ${isOpen ? "selectBox-open" : ""}`}>
                <button className={`selectBox_button ${classNameButton ? classNameButton : ""}`} onClick={e => { toggleMenu(e); resetSiblings() }}>{valueDefault}</button>
                <div className={`selectBox_list ${classNameList ? classNameList : ""}`}>
                    {
                        options.map(([key, value], index) => {
                            return (
                                <div className={`selectBox_item  ${classNameItem ? classNameItem : ""}`} key={index}>
                                    <input type="radio" id={nameInput + "_" + index} name={nameInput + "Item"} onClick={e => changeSelect(e)} data-id={key} data-texte={value}></input>
                                    <label htmlFor={nameInput + "_" + index}>{value}</label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        ) : (
                null
            )
    )
}

export default SelectBox;
