import React, { useState } from "react";

const FlexibleVideo = ({
  background,
  title,
  video,
  thumbnail,
  video_type,
  youtube_id,
}) => {
  const [isPlaying, setPlaying] = useState(false);

  const handleTimeUpdate = (e) => {
    setPlaying(!e.target.paused);
  };

  const handlePlayClick = (e) => {
    if (e.target.paused) {
      e.preventDefault();
      e.target.play();
      setPlaying(true);
    }
  };

  return (
    <div className={`flexible-video layout_module flexible-${background}`}>
      <div className="flexible-video-bloc">
        {video_type === "youtube" ? (
          <div className="flexible-video-youtube">
            <iframe
              title="video youtube"
              height="500"
              src={`https://www.youtube.com/embed/${youtube_id}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <div className={`flexible-video-video ${isPlaying ? "playing" : ""}`}>
            <video
              src={video}
              poster={thumbnail && thumbnail.url ? thumbnail.url : null}
              controls={isPlaying}
              preload="auto"
              playsInline
              loop
              onClick={(e) => handlePlayClick(e)}
              onTimeUpdate={(e) => handleTimeUpdate(e)}
            ></video>
          </div>
        )}

        <p className="flexible-video-titre">{title}</p>
      </div>
    </div>
  );
};
export default FlexibleVideo;
