import Device from "./device";

var Hotspot = function (infos, step, Sprite3D, nbFrames) {
    var elem = this;
    elem.infos = infos;
    elem.id = infos.id;
    elem.vertical = infos.vertical;
    elem.step = step;
    elem.context = Sprite3D.context;
    elem.canvas = Sprite3D.canvas;
    elem.selected = false;
    elem.selectedTimer = 0;
    elem.selectedTotalTimer = 20;
    elem.visible = false;
    elem.decalage = 0;
    elem.nbFrames = nbFrames;

    elem.init = function () {
        elem.decalage = Math.random() * Math.PI * 2;
        elem.dom = Sprite3D.main.querySelector(".sprite3D__hotSpotItem[data-id='" + elem.id + "']");

        elem.dom.classList.add("sprite3D__hotSpotItem--" + elem.vertical);

        if (elem.infos.x) {
            elem.withRotation = false;
        }
        else {
            elem.withRotation = true;
        }
    }();

    elem.render = function (currentStep, currentFrame, t, infosSprite) {
        elem.visible = false;

        if (infosSprite && currentStep == elem.step) {
            //Calcul de la rotation en cours
            if (elem.withRotation) {
                var currentAngle = (currentFrame / elem.nbFrames) * 2 * Math.PI;
                elem.nextAngle = (elem.infos.angle + currentAngle) % (2 * Math.PI);

                elem.x = 50 + (elem.infos.amplitudeX * Math.cos(elem.nextAngle)) * 0.07;
                elem.z = elem.infos.amplitudeZ * Math.sin(elem.nextAngle);
                elem.y = elem.infos.y - elem.z;
            }
            else {
                elem.x = elem.infos.x;
                elem.y = elem.infos.y;
            }

            if (elem.nextAngle == undefined || (elem.nextAngle > Math.PI && elem.nextAngle < 2 * Math.PI)) {
                var tempOpacity = 1;
                if (elem.nextAngle != undefined) {
                    tempOpacity = Math.abs(elem.nextAngle - 1.5 * Math.PI);
                    tempOpacity = tempOpacity - 0.4 * Math.PI;
                    tempOpacity = tempOpacity < 0 ? 0 : tempOpacity;
                    tempOpacity = 1 - tempOpacity / (0.1 * Math.PI);
                }

                elem.dom.classList.remove("sprite3D__hotSpotItem--left");
                elem.dom.classList.remove("sprite3D__hotSpotItem--right");
                if (elem.x < 50) {
                    elem.dom.classList.add("sprite3D__hotSpotItem--left");
                } else {
                    elem.dom.classList.add("sprite3D__hotSpotItem--right");
                }

                elem.trueWidth = (28 + 4 * Math.cos(t * 0.002 + elem.decalage)) * (Device.isLandscape ? 1 : 0.6);

                var margeTop = (elem.canvas.height - infosSprite.finalHeight) * 0.5;
                var margeLeft = (elem.canvas.width - infosSprite.finalWidth) * 0.5;

                elem.trueX = (infosSprite.finalWidth * elem.x / 100) + margeLeft
                elem.trueY = (infosSprite.finalHeight * elem.y / 100) + margeTop + infosSprite.finalMarginTop;

                if (elem.selected) {
                    elem.drawCircle(elem.trueX, elem.trueY, elem.trueWidth, "rgba(58,58,239,0.8)");
                    elem.drawCircle(elem.trueX, elem.trueY, elem.trueWidth * 0.5, "rgba(255,255,255," + tempOpacity + ")");
                    elem.dom.classList.add("sprite3D__hotSpotItem--visible");
                    elem.drawLine(elem.trueX, elem.trueY, tempOpacity);
                    elem.selectedTimer += 1;
                }
                else {
                    elem.drawCircle(elem.trueX, elem.trueY, elem.trueWidth, "rgba(58,58,239,0.8)");
                    //elem.drawCircle(elem.trueX, elem.trueY, elem.trueWidth * 0.5, "rgba(47,142,192," + tempOpacity + ")");
                    elem.drawCircle(elem.trueX, elem.trueY, elem.trueWidth * 0.5, "rgba(255,255,255," + tempOpacity + ")");
                    elem.dom.classList.remove("sprite3D__hotSpotItem--visible");
                    elem.selectedTimer = 0;
                }

                elem.visible = true;
            }
        }

        if (!elem.visible) {
            elem.selected = false;
            elem.dom.classList.remove("sprite3D__hotSpotItem--visible");
            elem.selectedTimer = 0;
        }
    }

    elem.drawLine = function (x, y, alpha) {
        if (Device.isLandscape) {
            var startX = x;
            var startY = y;

            var bound = elem.dom.getBoundingClientRect();
            var endX = (elem.dom.classList.contains("sprite3D__hotSpotItem--left") ? bound.left + bound.width + 10 : bound.left - 10);
            var endY = bound.top + 10;

            var halfX = (startX + endX) * 0.5;
            var halfY = y;

            var step1 = (elem.selectedTimer < elem.selectedTotalTimer * 0.5 ? elem.selectedTimer : elem.selectedTotalTimer * 0.5);
            var step2 = elem.selectedTimer - elem.selectedTotalTimer * 0.5;
            step2 = (step2 < 0 ? 0 : step2);
            step2 = (step2 > elem.selectedTotalTimer * 0.5 ? elem.selectedTotalTimer * 0.5 : step2);

            elem.context.strokeStyle = "rgba(255,255,255," + alpha + ")";
            elem.context.lineWidth = 2;

            if (step1 > 0) {
                elem.context.beginPath();
                elem.context.moveTo(x, y);
                elem.context.lineTo(x + (halfX - x) * (2 * step1 / elem.selectedTotalTimer), y + (halfY - y) * (2 * step1 / elem.selectedTotalTimer));
                elem.context.stroke();
                elem.context.closePath();

            }

            if (step2 > 0) {
                elem.context.beginPath();
                elem.context.moveTo(halfX, halfY);
                elem.context.lineTo(halfX + (endX - halfX) * (2 * step2 / elem.selectedTotalTimer), halfY + (endY - halfY) * (2 * step2 / elem.selectedTotalTimer));
                elem.context.stroke();
                elem.context.closePath();
            }
        }
    }

    elem.drawCircle = function (x, y, width, color) {
        elem.context.beginPath();
        elem.context.fillStyle = color;
        elem.context.arc(x, y, width, 0, 2 * Math.PI);
        elem.context.fill();
    }
}

export default Hotspot;