import React from "react";

const FlexibleCitation = ({ background, quote, name, job }) => {
  return (
    <div className={`flexible-citation layout_module flexible-${background}`}>
      <div className="flexible-citation-bloc">
        <p className="flexible-citation-quote"><span>{quote}</span></p>
        <p className="flexible-citation-name">{name}</p>
        <p className="flexible-citation-job">{job}</p>
      </div>
    </div>
  );
};
export default FlexibleCitation;
