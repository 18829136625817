import React from "react";
import SelectBox from "../../modules/select-box";

const InfosReglementees = ({ year, years, intro, sections, download_button, setYear }) => {

    if(!years || !sections){
        return null;
    }

    return (
        <div className="infosReg">

            <div className="infosReg-liste">
                <SelectBox nameInput='infosreg_year' options={Object.entries(years)} onChange={(e) => setYear(years[e])} classNameButton={"infosReg-select-button"} classNameList={"infosReg-select-list"} classNameItem={"infosReg-select-item"} defaultLabel={year}></SelectBox>
            </div>
            <div className="infosReg-entete">
                <h1 className="infosReg-entete__titre">{year}</h1>
                <div className="infosReg-entete-footer">
                    <span className="arrow-lign arrow"></span>
                    <h3>{intro}</h3>
                </div>
            </div>

            {sections.map((section, index) => (
                <div className="infosReg-information" key={index}>
                    <h2 className="infosReg-information__title">{section.category}</h2>

                    {section.documents.map((document, index) => (
                        <div className="infosReg-information-bloc-contenu" key={index}>
                            <p className="infosReg-information-bloc-contenu__date">{document.date}</p>
                            <div className="infosReg-information-bloc-contenu-blocdubas">
                                <p className="infosReg-information-bloc-contenu__text">{document.name}</p>
                                <div className="infosReg-information-bloc-contenu-blocdownload">
                                    <span className="icon-download"></span>
                                    <a
                                        className="infosReg-information-bloc-contenu-blocdownload__text"
                                        href={document.url ? document.url : undefined}
                                        download={document.name}
                                        target="blank"
                                    >
                                        {download_button}
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            ))}

        </div>
    )
}

export default InfosReglementees