import React from "react";

const FlexibleLine = ({ background }) => {
  return (
    <div className={`flexible-line layout_module flexible-${background}`}>
      <div className="flexible-line-bloc"></div>
    </div>
  );
};
export default FlexibleLine;
