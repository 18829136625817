import React from "react";

const FlexibleTexteMiseEnAvant = ({ background, text }) => {
  return (
    <div className={`flexible-texte-miseenavant layout_module flexible-${background}`}>
      <div className="flexible-texte-miseenavant-bloc" dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  );
};
export default FlexibleTexteMiseEnAvant;
