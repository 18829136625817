import { useDispatch } from 'react-redux';
import { updateLoaderStoreIsReady, updateLoaderStoreStatus, updateLoaderStoreImages } from '../../Store/action';

class ImageManager {

    static init() {
        this.initialised = true;
        this.sequences = {};
    }

    static addSequence(sequence) {
        let { name, fileNotAlpha, fileNotAlphaWebp, start, end, loop, trueStart, step = 1, position, auto = false } = sequence;

        if (!this.initialised) {
            this.init();
        }

        this.dispatch = useDispatch();

        if (this.sequences[name]) {
            return false;
        }

        this.sequences[name] = {
            fileNotAlpha,
            fileNotAlphaWebp,
            start,
            end,
            images: [],
            nbImagesCalculated: 0,
            nbImagesCalculatedTotal: (end - start + 1),
            trueDuration: (end - start + 1),
            loop: (loop ? loop : end),
            trueStart: (trueStart ? trueStart : start),
            step,
            position,
            auto,
            toLoad: []
        }
    }

    static checkWebp() {
        let elem = document.createElement("canvas");
        if (!!(elem.getContext && elem.getContext('2d'))) {
            return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }
        else {
            return false;
        }
    }

    static loadImages(device) {
        if (this.sequences && !this.loaded) {
            this.loaded = true;

            CheckConnexion.start(device, (quality, isWebp) => {
                let files = [];

                for (let name in this.sequences) {
                    if (!this.sequences[name].loading) {
                        this.sequences[name].loading = true;

                        this.sequences[name].quality = quality;
                        let { fileNotAlpha, fileNotAlphaWebp, start, end, trueStart, loop, step } = this.sequences[name];

                        if(isWebp){
                            fileNotAlpha = fileNotAlphaWebp;
                        }

                        if (quality === "slow") {
                            for (let i = 0; i < step; i++) {
                                let trueStep = (step === 1 ? 1 : i / (step - 1));
                                let realStart = (trueStart === end ? start : trueStart);
                                let index = Math.round(realStart + (trueStep * (loop - realStart)));
                                this.sequences[name].toLoad.push(index);
                            }
                            this.sequences[name].start = 0;
                            this.sequences[name].trueStart = (trueStart === end ? step - 1 : 0);
                            this.sequences[name].end = step - 1;
                            this.sequences[name].loop = step - 1;
                            this.sequences[name].nbImagesCalculatedTotal = step;
                        }

                        for (let i = start; i <= end; i++) {
                            if (this.sequences[name].toLoad.length === 0 || this.sequences[name].toLoad.indexOf(i) > -1) {

                                let notalpha = fileNotAlpha.split("[X]").join(this.getNumber(i, end));
                                let imageNotAlpha = new Image();
                                imageNotAlpha.fnLoad = e => this.checkLoading(e);
                                imageNotAlpha.fnError = e => this.checkLoadingError(e, true);
                                imageNotAlpha.indexTab = this.sequences[name].images.length;
                                imageNotAlpha.typeTab = "imageNotAlpha";
                                imageNotAlpha.sequenceName = name;
                                imageNotAlpha.url = notalpha;
                                files.push(imageNotAlpha);

                                this.sequences[name].images.push({
                                    imageNotAlpha
                                })
                            }
                        }
                    }
                }

                ImageDownload.start(files);
            });
        }
    }

    static getNumber(number, end) {
        let strEnd = "" + end;
        let finalNumer = "" + number;

        while (finalNumer.length < strEnd.length) {
            finalNumer = "0" + finalNumer;
        }

        return finalNumer;
    }

    static checkLoadingError(event, isError = false) {
        console.log("erreur");
        this.checkLoading(event, isError);
    }

    static checkLoading(event, isError = false) {
        let index = event.currentTarget.indexTab;
        let type = event.currentTarget.typeTab;
        let sequenceName = event.currentTarget.sequenceName;
        this.sequences[sequenceName].images[index][type].isLoaded = true;
        this.sequences[sequenceName].images[index][type].isError = isError;

        this.calculateImages(sequenceName, index);
    }

    static calculateImages(sequenceName, index) {
        let item = this.sequences[sequenceName].images[index];

        item.imageFinal = item.imageNotAlpha;

        this.sequences[sequenceName].nbImagesCalculated++;

        let totalImageCalculated = 0;
        let totalImage = 0;

        for (let key in this.sequences) {
            totalImageCalculated += this.sequences[key].nbImagesCalculated;
            totalImage += this.sequences[key].nbImagesCalculatedTotal;
        }

        this.dispatch(updateLoaderStoreStatus(totalImageCalculated / totalImage));

        if (totalImageCalculated >= totalImage) {
            this.dispatch(updateLoaderStoreImages(this.sequences));
            this.dispatch(updateLoaderStoreIsReady(true));
        }
    }
}
export default ImageManager

class CheckConnexion {
    static start(device, callback) {
        this.isWebP = false;
        this.timeStart = new Date();
        this.callback = callback;
        this.device = device;

        let seed = Math.random() * 10000000000;
        this.images = [
            "/test-connexion/_connexion1_nodelete.jpg?v=" + seed,
            "/test-connexion/_connexion2_nodelete.jpg?v=" + seed,
            "/test-connexion/_connexion3_nodelete.jpg?v=" + seed
        ]
        this.nbImageLoaded = 0;
        this.totalSize = 0;

        this.WebpIsSupported2(isSupported => {
            this.isWebP = isSupported;
            this.images.forEach(img => {
                let image = new Image();
                image.onload = e => this.checkLoading(e);
                image.src = img;
            })
        })        
    }

    static checkLoading(e) {
        this.nbImageLoaded++;

        let size = null;
        if (typeof performance != "undefined") {
            size = performance.getEntriesByName(e.currentTarget.src)[0].transferSize;
        }

        this.totalSize += (size ? size : 220000);

        if (this.nbImageLoaded >= this.images.length) {
            this.RATIO_MIN = (this.device.mobile ? 2 : 5); // On mets 5 mbits en ratio minimum et 2 si mobile

            this.timeEnd = new Date();
            let totalMs = 0.001 * (this.timeEnd - this.timeStart);
            let speed = this.totalSize / totalMs;

            if (speed > this.getTrueRatio(this.RATIO_MIN)) {
                this.callback("fast", this.isWebP);
            } else {
                this.callback("slow", this.isWebP);
            }
        }
    }

    static getTrueRatio(indice) {
        return indice * 1000000 / 8;
    }

    static WebpIsSupported(callback){
        if(!window.createImageBitmap){
            callback(false);
            return;
        }

        var webpdata = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

        fetch(webpdata).then(function(response){
            return response.blob();
        }).then(function(blob){
            createImageBitmap(blob).then(function(){
                callback(true);
            }, function(){
                callback(false);
            });
        });
    }

    static WebpIsSupported2(callback){
        let seed = Math.random() * 10000000000;
        let url = "/test-connexion/test.nodelete.webp?v=" + seed;

        let img = new Image();
        img.onload = () => callback(true);
        img.onerror = () => callback(false);
        img.src = url;
    }
}

class ImageDownload {
    static start(files) {
        this.NB_TOTAL = 15;
        this.files = files.slice(0);
        this.filesTodo = [];

        this.update();
    }

    static update() {
        while (this.filesTodo.length < this.NB_TOTAL && this.files.length > 0) {

            let file = this.files.splice(0, 1)[0];

            file.onload = e => {
                file.fnLoad(e);
                this.filesTodo.splice(this.filesTodo.indexOf(file), 1);
                setTimeout(() => this.update(), 30);
            }

            file.onerror = e => {
                file.fnError(e);
                this.filesTodo.splice(this.filesTodo.indexOf(file), 1);
                setTimeout(() => this.update(), 30);
            }

            file.src = file.url;

            this.filesTodo.push(file);
        }
    }
}