import React, { useEffect } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { updateGlobalStorePage } from "../Store/action";
import useApi from "../Hooks/useApi";
import { LoaderPage } from "../Components/loaders/loader";
import BackgroundPoints from "../modules/background-points";

// Components
import ComponentByJSON from "../Components/routes/component-from-json";
import Metatags from "../Components/metas-tags/meta-tags";
import Footer from "../Components/footer/footer";

const TemplateModule = ({ _uid }) => {
  const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("content-page"));
  }, [dispatch, isLoaded]);

  function renderPage() {
    const { metas, content } = dataFetch;

    return (
      <>
        <BackgroundPoints></BackgroundPoints>
        <Metatags {...metas} />
        {content &&
          content.flexibles &&
          content.flexibles.map((block) =>
            ComponentByJSON(block, content.title, content.anchors)
          )}
        <Footer />
      </>
    );
  }

  return isLoaded ? renderPage() : <LoaderPage />;
};

export default TemplateModule;
