import React, { useRef, useEffect, useState } from 'react';
import { useInView } from "react-intersection-observer";

const AnimatedTextes = ({ progress, textes, tolerance, auto }) => {

    const [state, setState] = useState("off");
    const [ref, inView] = useInView({ threshold: .5, infinite: true });

    useEffect(() => {
        if (inView) {
            setState("animating");
        } 
    }, [inView]);

    let refTextes = useRef(null);
    let [isReady, setReady] = useState(false);

    useEffect(() => {
        let timeout = setTimeout(() => setReady(true), 1000);

        return () => {
            clearTimeout(timeout);
        }
    }, []);

    const removeFixe = (index) => {
        if (refTextes.current) {
            refTextes.current["texteInfosMoving" + index] = false;
            let item = refTextes.current.querySelector("[data-id='" + index + "']");
            item.classList.remove("animated-textes--moving");
            item.classList.add("animated-textes--static");
        }
    }

    return (
        <div ref={auto ? ref : refTextes} className="animated-textes-container">
            {
                textes.map((txt, index) => {
                    let step = progress >= txt.percent + tolerance ? "before" : progress < txt.percent - tolerance ? "after" : "current";

                    let lastStep = "after";
                    let lastMove = "";
                    let moving = false;

                    if (refTextes.current) {
                        lastStep = (refTextes.current["texteInfos" + index] ? refTextes.current["texteInfos" + index] : lastStep);
                        lastMove = (refTextes.current["texteInfosMove" + index] ? refTextes.current["texteInfosMove" + index] : lastMove);
                        moving = (refTextes.current["texteInfosMoving" + index] ? refTextes.current["texteInfosMoving" + index] : moving);

                        if (step !== lastStep) {
                            if (step === "after" || (step === "current" && lastStep === "before")) {
                                lastMove = "droite";
                            } else if (step === "before" || (step === "current" && lastStep === "after")) {
                                lastMove = "gauche";
                            }

                            refTextes.current["texteInfosMove" + index] = lastMove;
                            refTextes.current["texteInfosMoving" + index] = moving = true;
                        }

                        refTextes.current["texteInfos" + index] = step;
                    }

                    return (
                        auto ?
                            (
                                <div key={index} data-revealed={state} data-id={index} className={`animated-textes animated-textes--auto ${isReady ? "animated-textes--ready" : ""} ${txt.classMore ? "animated-textes--" + txt.classMore : ""} animated-textes--${txt.profondeur} animated-textes--mobile${txt.profondeurMobile} animated-textes--${txt.position} ${txt.positionMobile ? "animated-textes--" + txt.positionMobile : ""}`} dangerouslySetInnerHTML={{ __html: txt.value }}></div>
                            ) : (
                                <div key={index} data-id={index} onTransitionEnd={() => removeFixe(index)} className={`animated-textes ${isReady ? "animated-textes--ready" : ""} animated-textes--${moving ? "moving" : "static"} ${txt.classMore ? "animated-textes--" + txt.classMore : ""} animated-textes--${txt.profondeur} animated-textes--mobile${txt.profondeurMobile} animated-textes--${txt.position} ${txt.positionMobile ? "animated-textes--" + txt.positionMobile : ""} ${lastStep !== "" ? "animated-textes--lastStep--" + lastStep : ""} ${lastMove !== "" ? "animated-textes--lastMove--" + lastMove : ""} animated-textes--${step}`} dangerouslySetInnerHTML={{ __html: txt.value }}></div>
                            )
                    )
                })
            }
        </div>
    )
}

export default AnimatedTextes;
