import React from "react";

const FlexibleChiffres = ({ background, blocks, text_align }) => {
  return (
    <div className={`flexible-chiffres layout_module flexible-${background}`}>
      {blocks && (
        <ul className="flexible-chiffres_liste">
          {blocks.map((block, index) => {
            return (
              <li
                className={`flexible-chiffres_item flexible-chiffres--${text_align}`}
                key={index}
              >
                <h3 className="flexible-chiffres_titre">{block.title}</h3>
                <div
                  className="flexible-chiffres_content"
                  dangerouslySetInnerHTML={{ __html: block.content }}
                ></div>
                <p className="flexible-chiffres_details">{block.details}</p>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default FlexibleChiffres;
