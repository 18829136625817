import React, { useState } from 'react';
import SelectBox from "../../modules/select-box";

const Filter = ({
  setYear,
  year,
  years,
  setCategory,
  category,
  categories,
  dictionnary,
  bigPadding,
}) => {

	const [reset, setReset] = useState('');

  return (
    <div className={`${bigPadding} bloc-filter`}>
      <p>{dictionnary.filter}</p>
      <div className="bloc-filter-list">
        <SelectBox
        	reset={reset == 'select__year'}
        	setReset={setReset}
          nameInput="press_year"
          options={Object.entries(years).reverse()}
          onChange={(e) => setYear(e)}
          classNameButton={"bloc-filter-select-button-date"}
          classNameList={"bloc-filter-select-list"}
          classNameItem={"bloc-filter-select-item"}
          defaultLabel={years[year]}
        ></SelectBox>
      </div>
      <div className="bloc-filter-list">
        <SelectBox
        	reset={reset == 'select__category'}
        	setReset={setReset}
          nameInput="press_category"
          options={Object.entries(categories)}
          onChange={(e) => setCategory(e)}
          classNameButton={"bloc-filter-select-button-category"}
          classNameList={"bloc-filter-select-list"}
          classNameItem={"bloc-filter-select-item"}
          defaultLabel={categories[category]}
        ></SelectBox>
      </div>
    </div>
  );
};

export default Filter;
