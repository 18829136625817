import React from "react";
import Link2 from "../../modules/link2";
import Wysiwyg from "../../modules/wysiwyg";
const FlexibleTexte = ({ background, text, cta, title }) => {
  return (
    <div className={`flexible-texte layout_module flexible-${background}`}>
      {title && <h3 className="flexible-texte__title">{title}</h3>}
      <Wysiwyg className="flexible-texte__texte">{text}</Wysiwyg>
      {cta && (
        <p
          className={`flexible-texte-cta cta cta--${cta.type} cta--${cta.type}-${background}`}
        >
          <Link2 to={cta.url} target={cta.target} type={cta.type}>
            {cta.title}
          </Link2>
        </p>
      )}
    </div>
  );
};
export default FlexibleTexte;
