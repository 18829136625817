import React from "react";
import NewsOnceComponent from "./newsOnceComponent";
import NewsBigComponent from "./newsBigComponent";

const News = ({
  articles,
  main_articles,
  next_page,
  dictionnary,
  setPage,
  colonneGauche,
  colonneDroite,
}) => {
  return (
    <div className="bloc-news">
      {/* bloc du haut  */}
      {main_articles.length > 0 && (
        <>
          <div className="bloc-news-upper">
            {/* chaque  gros bloc  */}
            {main_articles.map((article, index) => (
              <NewsBigComponent
                article={article}
                key={index}
                dictionnary={dictionnary}
              ></NewsBigComponent>
            ))}
          </div>
          <div className="bloc-news-lign">
            <div className="flexible-line-bloc"></div>
          </div>
        </>
      )}
      <div className="bloc-news-lower">
        <div className="bloc-news-lower-left">
          {colonneGauche.map((article, index) => (
            <NewsOnceComponent
              article={article}
              key={index}
            ></NewsOnceComponent>
          ))}
        </div>
        <div className="bloc-news-lower-right">
          {colonneDroite.map((article, index) => (
            <NewsOnceComponent
              article={article}
              key={index}
            ></NewsOnceComponent>
          ))}
        </div>
      </div>

      {/* Load more  */}
      {next_page && (
        <div className="news-loadmore">
          <p className="news-loadmore-cta news-loadmore-cta--border">
            <span onClick={(e) => setPage(next_page)}>...</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default News;
