import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Link2 from "../../modules/link2";

const BlocVideoSmallTexte = ({
  mainTitre,
  mediaType,
  mediaSrc,
  blocDisposition,
  secondTitre,
  texte,
  troisiemeTitre,
  imageSup,
  lastItem,
  cta,
}) => {
  const [state, setState] = useState("off");
  const [ref, inView] = useInView({ threshold: 0.2, infinite: false });

  useEffect(() => {
    if (inView) {
      setState("animating");
    }
  }, [inView]);

  return (
    <div ref={ref} className="layout">
      <div
        className={`bloc-video-small-texte ${
          lastItem ? "bloc-video-small-texte--last" : ""
        } ${mainTitre ? "bloc-video-small-texte--withMainTitle" : ""}`}
      >
        {mainTitre && (
          <div
            className="bloc-video-small-texte-mainTitre"
            data-revealed={state}
            dangerouslySetInnerHTML={{ __html: mainTitre }}
          ></div>
        )}
        <div
          className={`bloc-video-small-texte-bloc bloc-video-small-texte-bloc--${blocDisposition}`}
        >
          <div className="bloc-video-small-texte-video">
            <div
              className="bloc-video-small-texte-videoBox"
              data-revealed={state}
            >
              {mediaType === "video" && mediaSrc && (
                <video
                  src={mediaSrc.url}
                  autoPlay
                  muted
                  playsInline
                  preload="auto"
                  loop
                ></video>
              )}
              {mediaType === "image" && mediaSrc && (
                <img src={mediaSrc.url} alt={mediaSrc.alt} />
              )}
              {troisiemeTitre && (
                <div
                  className="bloc-video-small-texte-troisiemeTitre"
                  data-revealed={state}
                  dangerouslySetInnerHTML={{ __html: troisiemeTitre }}
                ></div>
              )}
            </div>
          </div>
          <div className="bloc-video-small-texte-subbloc" data-revealed={state}>
            {secondTitre && (
              <div
                className="bloc-video-small-texte-subbloc-titre"
                dangerouslySetInnerHTML={{ __html: secondTitre }}
              ></div>
            )}
            <p
              className="bloc-video-small-texte-subbloc-texte"
              dangerouslySetInnerHTML={{ __html: texte }}
            ></p>
            {cta && (
              <p className={`cta cta--border cta--borderSmaller`}>
                <Link2 to={cta.url} target={cta.target} type={cta.type}>
                  {cta.title}
                </Link2>
              </p>
            )}
          </div>
          {imageSup && (
            <div
              className="bloc-video-small-texte-imagesup"
              data-revealed={state}
            >
              <img src={imageSup.url} alt={imageSup.alt} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlocVideoSmallTexte;
