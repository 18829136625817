import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Controller, Scene } from "react-scrollmagic";
import AnimatedTextes from "./animated-textes";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import Link2 from "../../modules/link2";

const AnimatedImages = (props) => {
  const [state, setState] = useState(false);
  const [visible, setVisible] = useState(false);
  const [heightBloc, setHeightBloc] = useState();
  const [ref, inView, entry] = useInView({
    threshold: [0.2, 0.7],
    infinite: true,
  });
  let duration = 0;

  const handleResize = () => {
    setHeightBloc(window.innerHeight);
  };

  useEffect(() => {
    setState(inView);

    if (entry) {
      setVisible(entry.intersectionRatio >= 0.7);
    } else {
      setVisible(false);
    }
  }, [inView, entry]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  let data = useSelector((state) => state.loader).images[props.sequence];
  let devices = useSelector((state) => state.breakpoint);
  let isIOS =
    navigator.userAgent.indexOf("iPad") !== -1 ||
    navigator.userAgent.indexOf("iPhone") !== -1 ||
    navigator.userAgent.indexOf("iPod") !== -1;

  if (!data) {
    return null;
  }

  return (
    <>
      <div
        ref={ref}
        data-duration={
          (duration =
            (data.quality === "slow" && data.step === 1) || data.auto
              ? 1
              : data.trueDuration *
                (devices.desktop ? 1 : isIOS ? 1.25 : 1.65) *
                22)
        }
        data-step={data.auto ? 1 : data.step}
        className={`slide animated-images ${
          !props.apparitionOnIntersection || state
            ? "animated-images--visible"
            : "animated-images--hidden"
        } ${props.classMore ? "animated-images--" + props.classMore : ""}`}
      >
        <Controller>
          <Scene triggerHook="onLeave" duration={duration} pin>
            {(progress) => (
              <div
                style={{ height: heightBloc }}
                className={`animated-images-container ${
                  progress > 0.1 ? "animated-images-container--progressing" : ""
                }`}
              >
                <AnimatedImageRender
                  inView={state}
                  visible={visible}
                  progress={progress}
                  sequence={props.sequence}
                  seo={props.seo}
                  {...data}
                  {...devices}
                ></AnimatedImageRender>
                {props.textes && (
                  <AnimatedTextes
                    progress={progress}
                    auto={data.auto}
                    textes={props.textes}
                    tolerance={props.tolerance}
                  ></AnimatedTextes>
                )}
                {props.cta && (
                  <p
                    className={`animated-images-link cta cta--border cta--borderSmaller ${
                      progress > 0.01 || data.step === 1
                        ? "animated-images-link--visible"
                        : ""
                    }`}
                  >
                    <Link2
                      to={props.cta.url}
                      target={props.cta.target}
                      type={props.cta.type}
                    >
                      {props.cta.title}
                    </Link2>
                  </p>
                )}
              </div>
            )}
          </Scene>
        </Controller>
      </div>
    </>
  );
};

export default AnimatedImages;

class AnimatedImageRender extends Component {
  componentDidMount() {
    this.dom = ReactDOM.findDOMNode(this);
    this.canvas = this.dom;
    if (typeof this.currentLoop === "undefined") {
      this.currentLoop = 0;
    }
    if (typeof this.currentStart === "undefined") {
      this.currentStart = 0;
    }

    this.renderGraphics();
  }

  render() {
    return (
      <canvas>
        {this.props.seo && this.props.seo.canva ? this.props.seo.canva : null}
      </canvas>
    );
  }

  renderGraphics() {
    let infoParent = this.canvas.parentElement.getBoundingClientRect();
    let width = infoParent.width;
    let heightStyle = parseInt(this.canvas.parentElement.style.height);
    let height =
      (heightStyle ? heightStyle : infoParent.height) - this.canvas.offsetTop;

    let currentIndex = 0;

    if (!this.props.auto) {
      currentIndex =
        this.props.trueStart +
        Math.round(
          this.props.progress * (this.props.loop - this.props.trueStart)
        );

      if (this.currentStart < this.props.trueStart) {
        currentIndex = this.currentStart;
        this.currentStart += 0.5;
      }

      if (this.props.loop !== this.props.end) {
        if (this.props.progress === 1) {
          this.currentLoop =
            (this.currentLoop + 0.35 + this.props.end - this.props.loop) %
            (this.props.end - this.props.loop);
        } else {
          this.currentLoop = 0;
        }
      }

      currentIndex = Math.floor(currentIndex + this.currentLoop);
    } else {
      currentIndex = this.currentStart;

      if (
        (this.props.visible || this.currentStart > 0) &&
        this.currentStart < this.props.trueStart
      ) {
        this.currentStart += this.props.quality === "slow" ? 0.1 : 0.5;
      }

      if (!this.props.inView) {
        this.currentStart = 0;
        currentIndex = this.currentStart;
      }

      currentIndex = Math.floor(currentIndex);
    }

    if (
      this.lastWidth !== width ||
      this.lastHeight !== height ||
      this.lastIndex !== currentIndex
    ) {
      this.lastWidth = width;
      this.lastHeight = height;
      this.lastIndex = currentIndex;

      this.canvas.width = width;
      this.canvas.height = height;

      let ctx = this.canvas.getContext("2d");

      try {
        let img = this.props.images[currentIndex].imageFinal;

        let imgRatio = img.width / img.height;
        let windowRatio = width / height;

        let nextWidth = width;
        let nextHeight = height;

        if (imgRatio > windowRatio) {
          nextWidth = height * imgRatio;

          if (this.props.tablet && this.props.position === "center") {
            nextHeight = nextHeight * 0.8;
            nextWidth = nextWidth * 0.8;
          }
        } else {
          nextWidth = width * 0.9;
          nextHeight = (width / imgRatio) * 0.9;

          if (this.props.mobile) {
            nextWidth = width;
            nextHeight = width / imgRatio;
          }
        }

        if (this.props.mobile && width > 1.25 * height) {
          nextHeight = nextHeight * 0.5;
          nextWidth = nextWidth * 0.5;
        }

        let top = 0;
        if (
          this.props.position === "center" ||
          this.props.mobile ||
          this.props.tablet
        ) {
          top = 0.5 * (height - nextHeight);
        }

        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0.5 * (width - nextWidth),
          top,
          nextWidth,
          nextHeight
        );
      } catch {
        console.log(currentIndex);
      }
    }

    requestAnimationFrame(this.renderGraphics.bind(this));
  }
}
